import React from 'react';
import { makeStyles, Grid, Button, Hidden } from '@material-ui/core';
import Steps from '../Steps';
import BankVerfied from '../payment-option/BankVerified';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeliveryAddress from '../address/DeliveryAddress';
import EditButton from '../actions/EditButton';
import ReportDetails from './ReportDetails';
import PaymentMethod from './PaymentMethod';

const useStyles = makeStyles(theme => ({
  reviewPayWrapper: {
    '& .content-wrapper': {
      backgroundColor: '#fff',
      padding: '20px'
    },
    '& .green-check-icon': {
      color: '#59a053',
      height: 'auto',
      width: '18px'
    },
    '& .review-section': {
      marginTop: '20px'
    },
    '& .review-section:first-child': {
      marginTop: '0'
    },
    [theme.breakpoints.down('sm')]: {
      '& .content-wrapper': {
        paddingLeft: '0',
        paddingRight: '0',
        backgroundColor: '#fbfbfb',
        paddingBottom: '0'
      }
    }
  }
}));

export default function ReviewPay(props) {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${classes.reviewPayWrapper}`}>
      {/* <Steps stateIndex="1" stepType="active" /> */}
      <div className="content-wrapper">
        <div className="msite-container">
          <h2 className="main-heading">Review &amp; Pay</h2>
        </div>
        <Hidden smDown>
          <div className="review-section payment-method">
            <div className="msite-container">
              <h5 className="sub-heading">
                Payment Method
                <EditButton onClick={() => props.handleParticularStep(2)} />
              </h5>
            </div>
            <div className="box-panel">
              <PaymentMethod />
            </div>
          </div>
        </Hidden>
        <div className="review-section delivery-address">
          <div className="msite-container">
            <h5 className="sub-heading">
              Delivery Address
              <EditButton onClick={() => props.handleParticularStep(1)} />
            </h5>
          </div>
          <div className="box-panel">
            <DeliveryAddress checked={true} />
          </div>
        </div>
        <div className="review-section report-details">
          <div className="msite-container">
            <h5 className="sub-heading">Report Details</h5>
          </div>
          <ReportDetails />
        </div>
        <Hidden smUp>
          <div className="review-section payment-method">
            <div className="msite-container">
              <h5 className="sub-heading">
                Payment Method{' '}
                <EditButton onClick={() => props.handleParticularStep(2)} />
              </h5>
            </div>
            <div className="box-panel">
              <PaymentMethod />
            </div>
          </div>
        </Hidden>
      </div>
      <BankVerfied />
      {/* <Button
        variant="outlined"
        color="primary"
        className="mainButton"
        onClick={props.handleModalOpen}
      >
        verify OTP
      </Button> */}
    </div>
  );
}
