import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  editButtonWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    '& button': {
      padding: '0',
      color: '#3ba5e0',
      textTransform: 'capitalize'
    },
    '& svg': {
      width: '13px',
      marginRight: '5px'
    }
  }
}));

export default function EditButton({onClick}) {

  const classes = useStyles();
  return (
    <div className={classes.editButtonWrapper}>
      <Button color="primary" onClick={onClick}>
        <EditIcon /> Edit
      </Button>
    </div>
  );
}
