import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  payMethod: 'upi',
  rcNumber: ''
};

export const basicDetailSlice = createSlice({
  name: 'basicDetail',
  initialState,
  reducers: {
    updateValue: (state, action) => {
      console.log(action.payload);
      state.payMethod = action.payload;
    },
    resetValue: state => {
      state.payMethod = '';
    }
  }
});

export const { updateValue, resetValue } = basicDetailSlice.actions;
