import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme=>({
    customTabsSection:{
        padding:'0',
        '& ul':{
            '& li':{
                display:'inline-block',
                '& a':{
                    padding:'10px 15px',
                    display:'block',
                    color: '#4e4c4c',
                    textDecoration: 'none',
                    fontWeight:'500'
                },
                '& a.active':{
                    color:'#337ab7',
                    border:'1px solid #ddd',
                    borderBottomColor:'#fff',
                    borderTopLeftRadius:'5px',
                    borderTopRightRadius:'5px',
                    position:'relative',
                    zIndex:'1',
                    top:'1px'
                }
            },
        },
        '& .tab-content':{
            padding:'10px',
            border:'1px solid #ddd',
        }
    }
}))

const Tab =({children, active = 0})=>{
    const [activeTab, setActiveTab] = useState(active);
    const [tabsData, setTabsData] = useState([]);
    const classes = useStyles();
    useEffect(() =>{
        let data = [];
        React.Children.forEach(children, (element) => {
            if(!React.isValidElement(element)) return;
            const {
                props: {tab, children},
            } = element;
            data.push({tab, children});
        });
        setTabsData(data);
    }, [children])

    
    return (
        <div className={classes.customTabsSection}>
            <ul>
                {
                    tabsData.map(({tab}, idx) => (
                        <li key={idx}>
                            <a href="#" className={`nav-link ${idx === activeTab ? "active": ""}`}
                            onClick={()=> setActiveTab(idx)}
                            >
                                {tab}
                            </a>
                        </li>
                    ))
                }
            </ul>
            <div className="tab-content">
                {
                    tabsData[activeTab] && tabsData[activeTab].children
                }
            </div>
        </div>
    )
}
const TabPanel=({children})=>{
    return(
        children
    )
}
Tab.TabPanel = TabPanel;

export default Tab;