import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: false
};

export const panelSlice = createSlice({
  name: 'loginPanel',
  initialState,
  reducers: {
    changeValue: state => {
      state.value = true;
    },
    resetValue: state => {
      state.value = false;
    }
  }
});

export const { changeValue, resetValue } = panelSlice.actions;
