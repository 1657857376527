import { Paper, Button, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({
  summaryWrapper: {
    '& .heading': {
      margin: '0 0 10px',
      color: '#6f727b',
      fontSize: '18px',
      fontWeight: '500'
    },
    '& .total-amount': {
      backgroundColor: '#dae5f6',
      padding: '10px',
      display: 'flex',
      fontSize: '14px',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '5px'
    },
    '& .action-msite': {
      '& .total-amount': {
        backgroundColor: 'inherit',
        padding: '0',
        display: 'block',
        fontSize: '20px',
        '& span': {
          display: 'block',
          fontSize: '14px'
        }
      }
    },
    '& .pay-at-droom': {
      marginTop: '10px',
      textAlign: 'right',
      '& p': {
        margin: '0 0 10px',
        fontSize: '14px'
      },
      '& .heading': {
        fontSize: '22px',
        color: '#30343e',
        fontWeight: '600'
      }
    }
  }
}));

const REPORT_PRICE = 129;
export default function DetailSummary(props) {
  const classes = useStyles();

  return (
    <div className={classes.summaryWrapper}>
      <Hidden smDown>
        <div className="msite-container">
          <h3 className="heading">Summary</h3>
        </div>
      </Hidden>
      <Paper
        elevation={0}
        style={{ padding: '10px 20px', marginBottom: '20px' }}
      >
        <Hidden smDown>
          <div className="total-amount">
            <span>Total Amount</span>
            <strong>₹ {REPORT_PRICE}</strong>
          </div>
          <div className="pay-at-droom">
            <p>Pay at Droom Online</p>
            <h4 className="heading">₹ {REPORT_PRICE}</h4>
          </div>
        </Hidden>
        <Hidden smUp>
          <Grid container spacing={2} className="action-msite">
            <Grid item xs={5}>
              <div className="total-amount">
                <span>Total Amount</span>
                <strong>₹ {REPORT_PRICE}</strong>
              </div>
            </Grid>
            <Grid item xs={7}>
              <Button
                variant="contained"
                disabled={props.buttonDisable}
                color="primary"
                className="mainButton"
                fullWidth
                onClick={props.handleStep}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Hidden>
      </Paper>
      <Hidden smDown>
        <Paper elevation={0} style={{ padding: '10px 20px' }}>
          <Button
            variant="contained"
            disabled={props.buttonDisable}
            color="primary"
            className="mainButton"
            fullWidth
            onClick={props.handleStep}
          >
            Continue
          </Button>
        </Paper>
      </Hidden>
    </div>
  );
}
