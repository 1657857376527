import React from 'react';
import { makeStyles } from '@material-ui/core';
import PaymentMode from './PaymentMode';
import BankVerfied from './BankVerified';

const useStyles = makeStyles(theme => ({
  paymentOptionWrapper: {
    '& .content-wrapper': {
      backgroundColor: '#fff',
      padding: '20px'
    },
    '& button': {
      textTransform: 'capitalize',
      padding: '8px 30px'
    },
    '& .MuiButton-contained.Mui-disabled': {
      backgroundColor: '#a2adbd',
      color: '#fff'
    },
    [theme.breakpoints.down('sm')]: {
      '& .content-wrapper': {
        paddingLeft: '0',
        paddingRight: '0',
        backgroundColor: '#fbfbfb',
        paddingBottom: '0'
      }
    }
  }
}));

export default function PaymentOption() {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${classes.paymentOptionWrapper}`}>
      {/* <Steps stateIndex="1" stepType="active" /> */}
      <div className="content-wrapper">
        <PaymentMode />
      </div>
      <BankVerfied />
    </div>
  );
}
