import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(() => ({
  '@keyframes rotateEffect': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(359deg)'
    }
  },
  historyLoader: {
    textAlign: 'center',
    '& img': {
      maxWidth: '50px',
      animation: '$rotateEffect 1000ms infinite'
    }
  }
}));
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const classes = useStyles();
  const { loading, authenticated } = useSelector(state => state.auth);

  return (
    <Route
      {...rest}
      render={props => {
        if (loading) {
          return (
            <div className={classes.historyLoader}>
              <img
                src="https://cdn1.acedms.com/photos/images/web/beta/images/history/history_loader.gif"
                alt="loading..."
              />
            </div>
          );
        }

        if (!authenticated) {
          return <Redirect to="/" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
