import React, { useEffect } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import { useDispatch } from 'react-redux';
import { updateValue } from '@redux/reducers/basicDetailSlice';
import { useSelector } from 'react-redux';
const useStyles = makeStyles(theme => ({
  paymentModeWrapper: {
    '& .sub-heading': {
      margin: '0'
    },
    '& .details': {
      fontSize: '18px',
      '& .text-light': {
        color: '#6f727b',
        fontSize: '14px'
      }
    },
    '& .payment-icon': {
      textAlign: 'center',
      fontSize: '30px'
    },
    '& .payment-mode-wrapper': {
      marginTop: '20px'
    },
    '& .payment-mode-wrapper:first-child': {
      marginTop: '0'
    },
    '& .d-history-upi': {
      fontSize: '16px'
    },
    '& .d-history-card': {
      position: 'relative'
    },
    '& .d-history-card:after': {
      content: '"card"',
      position: 'absolute',
      left: '0',
      top: '0',
      fontSize: '12px',
      color: '#fff',
      right: '0',
      bottom: '0',
      margin: 'auto',
      height: '15px',
      textTransform: 'uppercase'
    },
    [theme.breakpoints.down('sm')]: {
      '& .payment-icon': {
        fontSize: '16px'
      },
      '& .d-history-card:after': {
        fontSize: '8px',
        height: '10px'
      },
      '& .d-history-upi': {
        fontSize: '14px'
      },
      '& .d-history-globe': {
        fontSize: '26px'
      },
      '& .d-history-card': {
        fontSize: '26px'
      }
    }
  }
}));

export default function PaymentMode() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { payMethod } = useSelector(state => state.basicDetail);
  const [selectedRadio, setSelectedRadio] = React.useState('upi');

  useEffect(() => {
    if (payMethod != '') {
      setSelectedRadio(payMethod);
    }
  }, [payMethod]);
  const handleRadioChange = event => {
    setSelectedRadio(event.target.value);
    dispatch(updateValue(event.target.value));
  };
  return (
    <div className={classes.paymentModeWrapper}>
      <div className="msite-container">
        <h2 className="main-heading">Select Payment Options to Pay</h2>
      </div>
      <div className="box-panel">
        <div className="msite-container">
          <Grid container spacing={2} className="payment-mode-wrapper">
            <Grid item xs={10} className="detail-block">
              <Radio
                color="primary"
                value="upi"
                name="paymentMode"
                id="upi"
                inputProps={{ 'aria-label': 'UPI' }}
                onChange={handleRadioChange}
                checked={selectedRadio === 'upi'}
              />
              <div className="detail-panel">
                <label htmlFor="upi" className="details">
                  <div>UPI (Droom Recommended, Fast & secure)</div>
                  <span className="text-light">
                    GPay , Phonepe & Paytm etc.
                  </span>
                </label>
              </div>
            </Grid>
            <Grid item xs={2} className="payment-icon">
              <i className="d-history-upi">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="payment-mode-wrapper">
            <Grid item xs={10} className="detail-block">
              <Radio
                color="primary"
                value="net_banking"
                name="paymentMode"
                id="net_banking"
                inputProps={{ 'aria-label': 'net_banking' }}
                onChange={handleRadioChange}
                checked={selectedRadio === 'net_banking'}
              />
              <div className="detail-panel">
                <label htmlFor="net_banking" className="details">
                  Net Banking
                </label>
              </div>
            </Grid>
            <Grid item xs={2} className="payment-icon">
              <i className="d-history-globe"></i>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="payment-mode-wrapper">
            <Grid item xs={10} className="detail-block">
              <Radio
                color="primary"
                value="debit_card"
                name="paymentMode"
                id="debit_card"
                inputProps={{ 'aria-label': 'debit_card' }}
                onChange={handleRadioChange}
                checked={selectedRadio === 'debit_card'}
              />
              <div className="detail-panel">
                <label htmlFor="debit_card" className="details">
                  Debit Card
                </label>
              </div>
            </Grid>
            <Grid item xs={2} className="payment-icon">
              <i className="d-history-card"></i>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="payment-mode-wrapper">
            <Grid item xs={10} className="detail-block">
              <Radio
                color="primary"
                value="credit_card"
                name="paymentMode"
                id="credit_card"
                inputProps={{ 'aria-label': 'credit_card' }}
                onChange={handleRadioChange}
                checked={selectedRadio === 'credit_card'}
              />
              <div className="detail-panel">
                <label htmlFor="credit_card" className="details">
                  Credit Card
                </label>
              </div>
            </Grid>
            <Grid item xs={2} className="payment-icon">
              <i className="d-history-card"></i>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
