import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    textAlign: 'center',
    paddingBottom: '20px',
    '& .icon-block': {
      marginRight: '20px',
      textAlign: 'center',
      fontSize: '100px'
    },
    '& .message-block': {
      '& .heading': {
        margin: '0',
        fontSize: '34px',
        fontWeight: '500',
        '& span': {
          fontSize: '20px',
          display: 'block',
          fontWeight: '400',
          color: '#6f727b',
          marginTop: '20px'
        }
      }
    },
    '& .message-error': {
      '& .icon-block': {
        width: '100px',
        height: '100px',
        margin: 'auto',
        backgroundColor: 'yellow',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
        '& svg': {
          fontSize: '46px'
        }
      }
    },
    // [theme.breakpoints.up('sm')]: {
    //   display: 'flex'
    // },
    [theme.breakpoints.down('sm')]: {
      '& .message-block': {
        textAlign: 'center',
        '& .heading': {
          fontSize: '16px',
          '& span': {
            fontSize: '14px'
          }
        }
      },
      '& .icon-block': {
        fontSize: '86px'
      }
    }
  }
}));

export default function ThankYouHeader({ downloadHandler }) {
  const classes = useStyles();
  const { user } = useSelector(state => state.auth);
  return (
    <div className={classes.headerWrapper}>
      <div className="message-success">
        <div className="icon-block">
          <i className="d-history-thank-you">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
            <span className="path5"></span>
            <span className="path6"></span>
          </i>
        </div>
        <div className="message-block">
          <h5 className="heading">
            Thank You {user.name} For Your Order On History!
            <span>
              We have shared the details with you via email and sms. <br /> You
              can also review the order details &amp; next steps on My Orders
              page.
            </span>
          </h5>
        </div>
      </div>
      <div className="message-error" style={{ display: 'none' }}>
        <div className="icon-block">
          <ReportProblemIcon />
        </div>
        <div className="message-block">
          <h5 className="heading">
            Error message {user.name} For Your Order On History!
            <span>error message text</span>
          </h5>
        </div>
      </div>
      <div className="download-report-block">
        <Button
          color="primary"
          variant="contained"
          className="download-button"
          onClick={downloadHandler}
        >
          Download Your Report
        </Button>
      </div>
    </div>
  );
}
