import React from "react";
import { Container, makeStyles } from '@material-ui/core';
import './static-pages.scss'
import Tab from "./CustomTabs";
import CustomDialog from "../Components/common/CustomDialog";
import PressOnline from "./press-release/PressOnline";
import ReadMoreData from "./press-release/ReadMoreData";
import PressOffline from "./press-release/PressOffline";

const useStyles = makeStyles(theme => ({
    pressContainer:{
        '& .date':{
            color:'gray',
            display:'flex',
            alignItems:'center',
            '& svg':{
                fontSize:'15px',
                marginRight:'5px'
            }
        },
        '& .box':{
            padding: theme.spacing(2),
            boxShadow:'0 0 10px rgba(0,0,0,0.3)',
            '&::after':{
                content:'""',
                display:'block',
                clear:'both',
            },
            '& .press-title':{
                fontWeight:'500'
            },
            '& .read-more-btn':{
                fontWeight:'500',
                float:'right',
                cursor:'pointer',
                display:'flex',
                alignItems:'center',
                '& svg':{
                    fontSize:'15px'
                }
            }
        },
        '& figure':{
            textAlign:'center',
            '& img':{
                maxWidth:'100%'
            }
        }
    },
    inThePressModal:{
        '& .MuiDialog-paperWidthSm': {
          width: '600px',
          minHeight:'400px',
          display:'grid',
          maxWidth:'100%',
        },
        '& .MuiDialog-scrollPaper': {
          overflow:'auto',
        },
        '& .date':{
            color:'gray',
            display:'flex',
            alignItems:'center',
            '& svg':{
                fontSize:'15px',
                marginRight:'5px'
            }
        },
        '& figure':{ 
            textAlign:'center',
            '& img':{
                maxWidth:'100%'
            },
        },
        '& .press-title':{
            fontWeight:'500'
        },
        [theme.breakpoints.down('sm')]: {
            color:'#ccc',
        }
    }
}));


const InThePress = () =>{
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [item, setItem] = React.useState('');
    const handlePressPopup = (pressItem) => {
        setOpen(!open);
        setItem(pressItem);
    }
    const handleClose = () => {
        setOpen(false);
    };

    const mainTabs = [
        {
            title: "Online",
            index: 1,
            content: <PressOnline onReadMoreModal={handlePressPopup} className={classes.pressContainer} />
        },
        {
            title: "Print",
            index: 2,
            content: <PressOffline onReadMoreModal={handlePressPopup} className={classes.pressContainer} />
        }
    ]

    return(
        <div id="in-the-press" className={`${classes.pressContainer}} static-page-wrapper`}>
            <Container className="container">
                <h1 className="section-heading">In The Press</h1>
                <Tab>
                    {
                        mainTabs.map((tab, idx) => (
                            <Tab.TabPanel key={`Tab-${idx}`} tab={tab.title}>
                                {tab.content}
                            </Tab.TabPanel>
                        ))
                        }
                </Tab>
            </Container>
            <CustomDialog
                open={open}
                onClose={handleClose}
                className={classes.inThePressModal}
                modalType="press-modal"
                // newsItem={item}
                newsItem = {<ReadMoreData pressItemData={item} />}
            />
        </div>
    )
}
export default InThePress;