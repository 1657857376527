import React from 'react';
import AppleIcon from '@material-ui/icons/Apple';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  downloadAppBlock: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    '& ul': {
      padding: '0'
    },
    '& li': {
      display: 'inline-block',
      '& i': {
        padding: '0 5px'
      },
      '& a': {
        textDecoration: 'none'
      }
    },
    '& .ios-app-btn': {
      color: 'black'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      textAlign: 'center',
      margin: 0,
      '& ul': {
        margin: 0
      }
    }
  }
}));

export default function DownloadApp() {
  const classes = useStyles();
  return (
    <div className={classes.downloadAppBlock}>
      <ul>
        <li>
          <a
            href="https://apps.apple.com/in/app/history-check-vehicle-history/id1259720055"
            target="_blank"
            title="iOS App"
            className="ios-app-btn"
            rel="noreferrer"
          >
            <i className="d-history-apple-black-logo"></i>
          </a>
        </li>
        <li>
          <a
            href="https://play.google.com/store/apps/details?id=com.droomhistory"
            target="_blank"
            title="Android App"
            rel="noreferrer"
          >
            <i className="d-history-android">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
              <span className="path5"></span>
              <span className="path6"></span>
              <span className="path7"></span>
              <span className="path8"></span>
            </i>
          </a>
        </li>
      </ul>
    </div>
  );
}
