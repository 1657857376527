import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import axios from 'axios';
const useStyles = makeStyles(theme => ({
  loginFormWrapper: {
    fontFamily: '"Cera Pro", sans-serif',
    '& .heading': {
      margin: '0 0 10px',
      fontWeight: '500',
      color: '#30343e'
    },
    '& .mainBtn': {
      padding: '10px',
      borderRadius: '5px',
      textTransform: 'inherit',
      fontSize: '16px',
      fontWeight: '500'
    },
    '& .submitBtn': {
      backgroundColor: '#2598fb',
      color: '#fff'
    },
    '& .signupBtn': {
      textTransform: 'inherit',
      fontWeight: '600',
      color: '#3ba5e0'
    },
    '& a': {
      color: '#2598fb'
    },
    '& .error': {
      color: '#f7222e'
    },
    '& p': {
      textAlign: 'center'
    },
    '& .MuiButton-contained.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    }
  }
}));

export default function SignupForm(props) {
  const classes = useStyles();
  const [enableButton, setEnableButton] = useState('');
  const [message, setMessage] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [errorFlag, setErrorFlag] = useState(false);
  const emailPattern = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const namePattern = new RegExp(/[a-zA-Z]/g);
  const enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === 13 && message.length === 10) {
      //13 is the enter keycode
      showContinueBtn(false);
    }
  };
  const handleChange = event => {
    setMessage(event.target.value);
    if (event.target.value.length > 9) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  };
  const inputRef = useRef();

  useEffect(() => {
    if (message.autofocus) {
      inputRef.current.focus();
    }
  }, [message.autofocus, inputRef]);

  const submitHandler = async () => {
    if (!emailPattern.test(userEmail)) {
      setErrorFlag(true);
    } else {
      props.handleResendMsg(message);
      const { data } = await axios.post('/drlogin/history-register', {
        user_name: userEmail,
        mobile: message,
        name: userName
      });
      // console.log(data.data.data._id)
      if (data.data.code == 'success') {
        props.otpModelHandle(data.data.data._id);
        // props.otpModelHandle(res.payload.data.data._id);
      }
    }
  };
  const handleNameChange = e => {
    if (
      namePattern.test(e.target.value) ||
      e.nativeEvent.inputType == 'deleteContentBackward'
    ) {
      setUserName(e.target.value);
    }
  };
  const handleEmail = e => {
    setUserEmail(e.target.value);
    setErrorFlag(false);
  };
  return (
    <div className={`${classes.loginFormWrapper} login-form`}>
      <form>
        <h3 className="heading">Register On History</h3>
        <TextField
          id="fullName"
          label="Name"
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={handleNameChange}
          value={userName}
          autoFocus={true}
        />
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          margin="normal"
          onChange={handleEmail}
          value={userEmail}
          fullWidth
        />
        {errorFlag && <p style={{ color: 'red' }}>Incorrect Email Id</p>}
        <TextField
          label="Mobile"
          id="mobileNumber"
          helperText="OTP will be sent to your registered email and mobile number."
          margin="normal"
          variant="outlined"
          type="number"
          fullWidth
          inputRef={inputRef}
          value={message}
          onChange={handleChange}
          onKeyPress={enterPressed}
          onInput={e => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 10);
          }}
        />
        <p>
          <Button
            className="mainBtn submitBtn"
            variant="contained"
            color="primary"
            disabled={!enableButton}
            fullWidth
            onClick={submitHandler}
          >
            Sign Up
          </Button>
        </p>
        <p>
          {/* <span className="error">Sorry Email is already link with another account</span>  */}
          <span>Already an account ? </span>
          <Button
            color="primary"
            className="signupBtn"
            onClick={props.changeToLogin}
          >
            Sign In
          </Button>
        </p>
        <p>
          <a href="/privacy-policy">Terms &amp; Privacy Policy</a>
        </p>
      </form>
    </div>
  );
}
