import axios from 'axios';
import loginDetails from './login-details';
import { isNode } from '../../config';

export default (req = null) => {
  const axiosIntance = axios.create({
    ...(isNode &&
      req.get('Cookie') && { headers: { Cookie: req.get('Cookie') } })
  });

  return {
    loginDetails: loginDetails(axiosIntance)
  };
};
