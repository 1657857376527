import React from 'react';
import { makeStyles, Container, Grid } from '@material-ui/core';
import './static-pages.scss'
export default function FaqsHistory() {
  return (
    <div id="faqs-history" className="static-page-wrapper">
      <Container className="container">
        <h1 className="section-heading">FAQs</h1>
        <section className="section-wrapper">
          <h2 className="sub-heading">What is the source of information of History Report?</h2>
          <p>The Vehicle History report is completely unbiased and is based on historical data available at various authorized data providers. </p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">Can I sell Vehicle History Report?</h2>
          <p>No, Droom reserves all the rights to sell the History Report. End user(s) can use vehicle&apos;s history report for personal reference while buying or selling a vehicle. Droom may take a legal action against anyone found misusing the report.
          </p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">How is a Vehicle History Report helpful?</h2>
          <p>Vehicle History Report helps in taking an informed decision before purchasing a used vehicle. It provides important information about vehicle&apos;s background, covering upto 42 different history records that can save you from being a victim to a fraud. </p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">Why is the Vehicle History Report I am looking for not available?</h2>
          <p>There is a possibility that currently the report is not available in our database. We are constantly increasing our database to provide users with History Report of every used vehicle. We request you to try after sometime.</p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">How will I receive my Vehicle History Report?</h2>
          <p>Your Vehicle History Report (Basic or Premium) will be available on the result page. You can directly download the history report using a link provided at end of the process. A detailed report will also be sent to your e-mail id in upto 30 minutes. </p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">What is the cost of the History Report?</h2>
          <p>here is nominal fee of starting @ &#x20b9;49 for History Gold Report & @ &#x20b9;149 for History Platinum Report. </p>
        </section>
      </Container>
    </div>
  );
}
