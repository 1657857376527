const allRouteMeta = [
  {
    path: '/',
    name: 'description',
    content:
      'Check RTO vehicle information, vehicle owner details such as owner name, address, vehicle registration details, and much more at Vehicle History.',
    title:
      'Check RTO Vehicle Information, Vehicle Owner Details Online in India'
  },
  {
    path: '/blog',
    name: 'description',
    content:
      'Know All About the vehicle history news and update. read latest informative blogs related to history',
    title: 'All Vehicle History Report Update and News'
  },
  {
    path: '/faqs',
    name: 'description',
    content: 'Read all frequently asked questions of vehicle history',
    title: 'Vehicle History FAQs'
  },
  {
    path: '/parameters',
    name: 'description',
    content: 'History report contains various type of parameter of vehicle',
    title: 'History Parameters of Vehicle'
  },
  {
    path: '/terms-of-use',
    name: 'description',
    content:
      'Know about terms and use of the History like account, membership eligibility, service payment and communication',
    title: 'Terms of Use | History'
  },
  {
    path: '/glossary',
    name: 'description',
    content:
      'History glossary contains various type of information of vehicle such as brand, Registration date, age, fuel type, insurance etc',
    title: 'History Glossary'
  },
  {
    path: '/privacy-policy',
    name: 'description',
    content:
      'Kindly read this Privacy Policy and our Terms of Use carefully about vehicle history report',
    title: 'Privacy Policy | Vehicle History'
  },
  {
    path: '/in-the-press',
    name: 'description',
    content:
      'Get all the News and Information about Vehicle History in this Segment. Now, you can be more Informed about our services and new launches',
    title: 'Online Press Release About Vehicle History'
  },
  {
    path: '/what-history',
    name: 'description',
    content:
      'History certificate is available for any car, bike, or scooter in India, it helps you to get the information about vehicle details',
    title: 'Vehicle History Tool: Get Car, Bike & Scooter History Certificate'
  },
  {
    path: '/scooter-history-report',
    name: 'description',
    content:
      'Scooter Information – For registered scooter, you need to enter the Scooter Registration Number in the valid Format to get the owner details of scooter',
    title:
      'Scooter Registration Details, Check Online Scooter History Certificate'
  },
  {
    path: '/bike-history-report',
    name: 'description',
    content:
      'Bike Information- Check bike history report or Trace bike owner by Registration number in India? We have Service that Lets Know the bike details online',
    title:
      'Bike Registration Details, Check Online Used Motorcycle History Certificate'
  },
  {
    path: '/car-history-report',
    name: 'description',
    content:
      'Car Information- Trace Car History Report Online in India. RTO Details with Address, Registration number and Indian Car owner report online',
    title: 'Car Registration Details, Check Online Used Car History Report'
  },
  {
    path: '/methodology',
    name: 'description',
    content:
      'History is the most advanced and comprehensive tool uses of uses complex algorithmic approach and data science methodologies.',
    title: 'History Methodology and Working Process'
  },
  {
    path: '/blog/how-to-get-vahan-information-online',
    name: 'description',
    content:
      'At History only two-step process if you want to find the Vahan information online in India.',
    title: 'How to get Vahan Information Online?'
  },
  {
    path: '/blog/how-to-find-vehicle-owner-contact-details-by-registration-number',
    name: 'description',
    content:
      'We have our tool to fetch Vehicle owner contact details, while you enter a registration number of any registered motor vehicle.',
    title: 'How to Find Vehicle Owner Contact Details by Registration Number'
  },
  {
    path: '/blog/check-vehicle-details-online-on-history',
    name: 'description',
    content:
      'History has the largest vehicle details online database in India, You can find out vehicle details along with owner, owner, type etc.',
    title: 'Check Vehicle Details Online on History Website'
  }
];
export default allRouteMeta;
