import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const initialState = {
  loading: false,
  otpSended: false,
  error: ''
};
export const sendOtp = createAsyncThunk('opt/send', mobile => {
  return axios.post('/drlogin/history-otp', { email: mobile }).then(res => {
    return res.data;
  });
});
export const otpSlice = createSlice({
  name: 'otpHandler',
  initialState,
  extraReducers: builder => {
    builder.addCase(sendOtp.pending, state => {
      state.loading = true;
    });
    builder.addCase(sendOtp.fulfilled, (state, action) => {
      (state.loading = false),
        (state.otpSended =
          action.payload.data.code === 'success' ? true : false),
        (state.error = action.payload.data.errors || '');
    });
    builder.addCase(sendOtp.rejected, (state, action) => {
      (state.loading = false),
        (state.otpSended = false),
        (state.error = action.error.message);
    });
  }
});
