import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import DeliveryAddress from './DeliveryAddress';
import EditRemoveAction from '../actions/EditRemoveAction';
import AnotherEmailId from './AnotherEmailId';
import { useSelector,useDispatch } from 'react-redux';
const useStyles = makeStyles(theme => ({
  addressDetailWrapper: {
    '& .box-panel.active': {
      backgroundColor: '#efffe8'
    },
    '& .content': {
      paddingTop: '10px',
      '& p': {
        marginTop: '0',
        fontSize: '14px'
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .box-panel': {
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        borderRadius: '0',
        paddingBottom: '0'
      }
    }
  }
}));

export default function AddressDetails(props) {
  const classes = useStyles();
  const [setPanel, setPanelActive] = React.useState('box-panel active');
  const dispatch=useDispatch();
  const {authenticated,user} = useSelector(state => state.auth);

  const handleRadioCheck = event => {
    setPanelActive('box-panel active');
  };

  return (
    <div className={classes.addressDetailWrapper}>
      <div className="msite-container">
        <h2 className="main-heading">Your Address</h2>
        <h5 className="sub-heading">Select Delivery Address</h5>
      </div>

      <div className={setPanel}>
        <Grid container spacing={2}>
          <Grid item sm={9} xs={12}>
            <DeliveryAddress handleRadioCheck={handleRadioCheck} user={user}/>
          </Grid>
          <Grid item sm={3} xs={12}>
            {/* <EditRemoveAction /> */}
          </Grid>
        </Grid>
      </div>
      {/* <AnotherEmailId
        setBackground={props.setBackground}
        emailUpdate={props.emailUpdate}
        handleModalOpen={props.handleModalOpen}
        user={user}
      /> */}
    </div>
  );
}
