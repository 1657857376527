import React from 'react';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';
import { createFrontloadState } from 'react-frontload';
import { BrowserRouter } from 'react-router-dom';

import { App } from './components';
import services from './services';
import configureStore from './redux/store';

const frontloadState = createFrontloadState.client({
  context: { api: services() },
  serverRenderedData: window.__UNIVERSSR_FRONTLOAD_DATA__
});

const preloadedState = window.__UNIVERSSR_PRELOADED_STATE__;
const store = configureStore(preloadedState);

const Client = () => {
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <BrowserRouter>
      <App frontloadState={frontloadState} store={store} />
    </BrowserRouter>
  );
};

loadableReady(() => {
  hydrate(<Client />, document.getElementById('root'));
});

// temp fix for webpack 5
// @see: https://github.com/webpack-contrib/webpack-hot-middleware/issues/390
if (module.hot) {
  module.hot.accept();
}
