const helmet = {
  htmlAttributes: { lang: 'en' },
  link: [
    {
      rel: 'stylesheet',
      href: 'https://cdnjs.cloudflare.com/ajax/libs/normalize/7.0.0/normalize.min.css'
    },
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/icons/favicon.ico'
    }
  ],
  meta: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
    },
    {
      name: 'twitter:card',
      content: 'summary'
    },
    {
      name: 'twitter:site',
      content: '@droom_history'
    },
    {
      name: 'twitter:creator',
      content: 'https://vehiclehistory.in/'
    },
    {
      name: 'twitter:image:src',
      content:
        'https://cdn1.acedms.com/photos/images/web/beta/images/history/banner-image-new.png'
    },
    {
      name: 'twitter:app:name:googleplay',
      content: 'Droom History'
    },
    {
      name: 'og:type',
      content: 'website'
    },
    {
      name: 'og:image',
      content:
        'https://cdn1.acedms.com/photos/images/web/beta/images/history/banner-image-new.png'
    },
    {
      name: 'og:site_name',
      content: 'Droom History'
    },
    {
      name: 'og:locale',
      content: 'en_US'
    },
    {
      name:"google-site-verification",
      content:"QlYkpxEiauiBYtn3XI2po56XOVHMcrr7vonoktBblas"
    }
  ]
};

export default helmet;
