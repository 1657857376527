import React from "react";
import { Container } from '@material-ui/core';
import './static-pages.scss'

const Glossary =() =>{
    return(
        <div id="history-glossary" className="static-page-wrapper">
            <Container className="container">
                <h1 className="section-heading">History Glossary</h1>
                <section className="section-wrapper" style={{backgroundColor:'#ffe3ce', borderRadius:'10px', padding:'10px', marginBottom:'20px'}}>
                    <p><strong>Registration Number:</strong> It is the unique number on which a vehicle is registered. This number is written on the number plate of the Indian vehicles. It is also known as RC number</p>
                    <p><strong>Brand:</strong> It specifies the brand or model of the vehicle that is being sold under a marque of a particular manufacturer/make e.g. Swift, City etc.</p>
                    <p><strong>Date of Registration:</strong> It is the date on which the vehicle got registered in the RTO office</p>
                    <p><strong>Vehicle Age:</strong> The Vehicle Age defines how old is the vehicle from the current date</p>
                    <p><strong>Fuel Type:</strong> It is the fuel on which the vehicle runs, e.g. Petrol, CNG etc.</p>
                    <p><strong>Chassis Number:</strong> Chassis number or VIN (Vehicle Identification Number) is the unique code given to the vehicle based on various parameters like country of manufacturing, manufacturer, etc.</p>
                    <p><strong>Engine Number:</strong> The serial number of the engine of the vehicle</p>
                    <p><strong>Vehicle Type:</strong> It defines the category of the vehicle. Also called vehicle class, e.g. L.M.V. (Light Motor Vehicle), sedan, hatchback etc.</p>
                    <p><strong>Registration Authority:</strong> The registration office under which the vehicle is being registered</p>
                    <p><strong>Registration State:</strong> The Indian state where a vehicle has been registered.</p>
                    <p><strong>Owner Serial Number:</strong> It is the serial number of ownership of the vehicle. It shows how many times a vehicle has been sold.</p>
                    <p><strong>Owner Name:</strong> The name of the owner on whose name a vehicle has been registered at the RTO</p>
                    <p><strong>Financing Authority:</strong> The company which has financed a vehicle.</p>
                    <p><strong>Blacklisted:</strong> Any vehicle that violates the traffic rule gets backlisted. It the defines the vehicles which have been blacklisted</p>
                    <p><strong>Blacklisted Reason:</strong> The reason for which the vehicle has been blacklisted</p>
                </section>
            </Container>
        </div>
    )
}
export default Glossary;