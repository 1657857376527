import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Hidden } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  reportDetailWrapper: {
    fontSize: '14px',
    color: '#30343e',
    '& .sub-heading': {
      margin: '0'
    },
    '& strong': {
      fontWeight: '600'
    },
    '& .details': {
      listStyle: 'none',
      '& .text-light': {
        color: '#6f727b',
        fontSize: '14px'
      },
      '& li': {
        marginTop: '10px'
      },
      '& li:first-child': {
        marginTop: '0'
      }
    },

    [theme.breakpoints.down('sm')]: {}
  }
}));

export default function ReportDetails() {
  const classes = useStyles();
  const [rcNumber, setRcNumber] = useState('');
  useEffect(() => {
    if (window.sessionStorage.getItem('rc_no')) {
      setRcNumber(window.sessionStorage.getItem('rc_no'));
    }
  }, [rcNumber]);
  return (
    <div className={classes.reportDetailWrapper}>
      <div className="box-panel">
        <div className="msite-container">
          <Grid container spacing={2} className="payment-mode-wrapper">
            <Grid item xs={3} sm={2} className="image-block">
              <figure>
                <img
                  src="https://cdn1.acedms.com/photos/images/web/beta/images/history/sample-reports/sample-report.png"
                  alt="history sample report"
                />
              </figure>
            </Grid>
            <Grid item xs={9} sm={8} className="detail-block">
              <div className="detail-panel">
                <ul className="details">
                  <li>Premium History Report</li>
                  <li>
                    <span className="text-light">Report Cost</span>{' '}
                    <strong>₹ 129</strong>
                  </li>
                  <li>
                    <span className="text-light">Registration No.</span>{' '}
                    <strong>{rcNumber}</strong>
                  </li>
                </ul>
              </div>
            </Grid>
            <Hidden smDown>
              <Grid item sm={2} className="pay-online">
                <span>Pay online</span>
                <strong>₹ 129</strong>
              </Grid>
            </Hidden>
          </Grid>
        </div>
      </div>
      <p>
        <strong>Terms &amp; Condition:</strong> A purchased history certificate
        cannot be cancelled, refunded or returned.
      </p>
    </div>
  );
}
