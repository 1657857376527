import React from 'react';
import { makeStyles } from '@material-ui/core';
import IconList from '../common/IconList';
// const useStyles = makeStyles(theme => ({
//   followUsWrapper: {
//     '& ul': {
//       listStyle: 'none',
//       margin: '0',
//       display: 'flex',
//       alignContent: 'center',
//       '& li a': {
//         textDecoration: 'none',
//         fontSize: '26px',
//         '& > span': {
//           height: '33px',
//           display: 'block'
//         }
//       },
//       '& li': {
//         marginLeft: '10px'
//       },
//       '& li:first-child': {
//         marginLeft: '0'
//       }
//     },
//     [theme.breakpoints.down('sm')]: {}
//   }
// }));
const followUsIcons = [
  {
    id: 1,
    icon: '<i class="d-history-facebook"><span class="path1"></span><span class="path2"></span></i>',
    alt: 'facebook',
    url: 'https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fdroomhistory.com%2F&amp;src=sdkpreparse'
  },
  {
    id: 2,
    icon: '<i class="d-history-twitter"><span class="path1"></span><span class="path2"></span></i>',
    alt: 'twitter',
    url: 'https://twitter.com/intent/tweet?text=Check%20Any%20Vehicle%27s%20History%20online%20-%20Vehicle%20History%20!%20http://www.vehiclehistory.in'
  },
  {
    id: 3,
    icon: '<i class="d-history-whatsapp"></i>',
    alt: 'whatsapp',
    url: `whatsapp://send?text=Check Any Vehicle's History online - Vehicle History ! http://www.vehiclehistory.in`
  },
  {
    id: 4,
    icon: '<i class="d-history-linkedin"><span class="path1"></span><span class="path2"></span></i>',
    alt: 'linkedIn',
    url: 'https://www.linkedin.com/shareArticle?mini=true&amp;url=https://vehiclehistory.in'
  }
];


export default function FollowUs(props) {

  return (
    <IconList icons={followUsIcons}/>
  );
}
