import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '../../components';
import helmet from '../../static/helmet';
import styles from './Root.module';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import '../icomoon/style.css';
import '../../theme/CeraPro/fonts.css';
import './index.css';
import ProtectedRoute from './ProtectedRoute';
import { makeStyles } from '@material-ui/core';

import { loginSlice } from '../../redux/reducers/loginSlice';
import { useDispatch } from 'react-redux';
import { useFrontload } from 'react-frontload';
import allRouteMeta from '../../static/allRouteMeta';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "'Cera Pro', sans-serif"
  },
  mainWrapper: {
    paddingTop: '75px'
  },
  [theme.breakpoints.up('md')]: {}
}));

export default function App({ routes }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data, frontloadMeta } = useFrontload('RootApp', async ({ api }) => {
    const data = await api.loginDetails.get();
    return data.data;
  });

  const { pathname: currentPath } = useLocation();
  const metaData = React.useMemo(
    () => allRouteMeta.find(element => element.path == currentPath),
    [currentPath]
  );
  const helmetConfig = {
    ...helmet,
    link: [
      ...helmet.link,
      {
        rel: 'canonical',
        href: `https://vehiclehistory.in${currentPath}`
      }
    ],
    ...(metaData && {
      meta: [
        ...helmet.meta,
        {
          name: metaData.name,
          content: metaData.content
        },
        {
          property: 'og:title',
          content: metaData.title
        },
        {
          property: 'og:description',
          content: metaData.content
        },
        {
          property: 'og:url',
          content: `https://vehiclehistory.in${currentPath}`
        },
        {
          property: 'twitter:title',
          content: metaData.title
        },
        {
          property: 'twitter:description',
          content: metaData.content
        }
      ],
      title: metaData.title
    })
  };

  useEffect(() => {
    dispatch(loginSlice.actions.authDataSuccess(data));
  }, [data, dispatch]);

  if (frontloadMeta.pending) return <div>loading</div>;
  if (frontloadMeta.error) return <div>error</div>;

  return (
    <div className={`${styles.container} ${classes.root}`}>
      <Helmet {...helmetConfig} />
      <Header />
      <div className={`${styles.mainWrapper} ${classes.mainWrapper}`}>
        <ErrorBoundary>
          <Switch>
            {routes.map((item, index) => {
              if (item.isPublic) {
                return <Route key={index} {...item} />;
              }
              return <ProtectedRoute key={index} {...item} />;
            })}
          </Switch>
        </ErrorBoundary>
        <Footer />
      </div>
    </div>
  );
}
