import React, { useState } from 'react';
import { makeStyles, Container, Grid, Hidden } from '@material-ui/core';
import DownloadAppScan from './DownloadAppScan';
import { Link } from 'react-router-dom';
import FollowUs from './FollowUs';
import ServiceByDroom from './ServiceByDroom';
import CustomDialog from '../common/CustomDialog';
import MediaIcons from './MediaIcons';

const useStyles = makeStyles(theme => ({
  footerContainer: {
    backgroundColor: '#f8f8f8',
    borderTop: '2px solid #d9effa',
    padding: '20px 0',
    '& .container': {
      padding: theme.spacing(0, 1.25),
      maxWidth: '1170px',
      ['@media(min-width: 1370px)']: {
        maxWidth: '1280px'
      }
    },
    '& .menu-items ul': {
      listStyle: 'none',
      '& li': {
        lineHeight: '2',
        '& a, .customLinkElm': {
          textDecoration: 'none',
          color: '#30343e',
          fontSize: '16px',
          cursor: 'pointer'
        },
        '& a:hover, & a:focus, .customLinkElm:hover, .customLinkElm:focus': {
          color: '#2598fb'
        }
      }
    },
    '& h6, & h5': {
      fontSize: '20px',
      fontWeight: '500',
      color: '#30343e',
      margin: '0',
      padding: '0'
    },
    '& h5': {
      marginBottom: '20px'
    },
    '& .footer-social': {
      display: 'flex',
      alignItems: 'center',
      marginTop: '30px',
      '& figure': {
        margin: '0'
      },
      '& .footer-left-column': {
        alignItems: 'flex-end'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& h5': {
        marginBottom: '10px'
      },
      '& .menu-items': {
        marginBottom: '20px'
      },
      '& .follow-us-wrapper': {
        marginBottom: '30px'
      }
    }
  },
  launchVideoModal: {
    '& .MuiDialog-paperWidthSm': {
      width: '600px',
      minHeight: '400px',
      display: 'grid',
      maxWidth: '100%'
    },
    '& .MuiDialog-scrollPaper': {
      overflow: 'auto'
    },
    '& iframe': {
      width: '100%',
      height: '100%'
    }
  }
}));
const footerNavItems = [
  {
    id: 1,
    heading: 'About History',
    menuItems: [
      {
        id: 1,
        menuItem: 'What is History',
        path: '/what-history'
      },
      {
        id: 2,
        menuItem: 'Why History',
        type: 'why-history',
        path: '/#why-history'
      },
      {
        id: 3,
        menuItem: 'Parameters',
        path: '/parameters'
      },
      {
        id: 4,
        menuItem: 'FAQs',
        path: '/faqs'
      },
      {
        id: 5,
        menuItem: 'Privacy Policy',
        path: '/privacy-policy'
      },
      {
        id: 6,
        menuItem: 'Terms of Use',
        path: '/terms-of-use'
      }
    ]
  },
  {
    id: 2,
    heading: 'Offerings',
    menuItems: [
      {
        id: 1,
        menuItem: 'Car History Report',
        path: '/car-history-report'
      },
      {
        id: 2,
        menuItem: 'Bike History Report',
        path: '/bike-history-report'
      },
      {
        id: 3,
        menuItem: 'Scooter History Report ',
        path: '/scooter-history-report'
      },
      {
        id: 4,
        menuItem: 'Methodology',
        path: '/methodology'
      },
      {
        id: 5,
        menuItem: 'Glossary',
        path: '/glossary'
      },
      {
        id: 6,
        menuItem: 'Blog',
        path: '/blog'
      }
    ]
  },
  {
    id: 3,
    heading: 'Media',
    menuItems: [
      {
        id: 1,
        type: 'ceo',
        menuItem: 'Sandeep Aggarwal(Founder & CEO)',
        path: 'https://sandeepaggarwal.com/'
      },
      {
        id: 2,
        menuItem: 'Launch Video',
        path: ''
      },
      {
        id: 3,
        menuItem: 'In the Press',
        path: '/in-the-press'
      }
    ]
  },
  {
    id: 4,
    heading: 'Let Us Help You',
    menuItems: [
      {
        id: 1,
        menuItem: 'Contact Us',
        path: ''
      },
      {
        id: 2,
        menuItem: '9650 08 08 08',
        path: ''
      },
      {
        id: 3,
        type: 'email',
        menuItem: 'support@vehiclehistory.in',
        path: 'mailto:support@vehiclehistory.in'
      }
    ]
  }
];

export default function Footer() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [videoPause, setVideoPause] = React.useState(
    'https://www.youtube.com/embed/7ARS4c6licU'
  );
  const handleVideoPopup = () => {
    setOpen(!open);
    setVideoPause('https://www.youtube.com/embed/7ARS4c6licU');
  };
  const handleClose = () => {
    setOpen(false);
    setVideoPause(null);
  };
  const goToTop = () => {
    window.scrollTo(0, 0);
  };
  const handleWhyHistoryRef = () => {
    setTimeout(() => {
      const element = document.getElementById('why-history');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <div className={classes.footerContainer}>
      <Container maxWidth="lg" className="container">
        <Hidden mdUp>
          <Grid container>
            <Grid item xs={12} className="">
              <DownloadAppScan />
            </Grid>
            <Grid item xs={12} className="">
              <div className="follow-us-wrapper">
                <FollowUs />
                <h6>Follow Us</h6>
              </div>
            </Grid>
          </Grid>
        </Hidden>
        <Grid container spacing={2}>
          {footerNavItems.map((item, index) =>
            item.id === 4 ? (
              <Grid item sm={3} xs={12} className="menu-items" key={index}>
                <h5>{item.heading}</h5>
                <ul>
                  {item.menuItems.map((menu, i) => (
                    <li key={i}>
                      {menu.type === 'email' ? (
                        <a href="mailto:support@vehiclehistory.in">
                          support@vehiclehistory.in
                        </a>
                      ) : // <Link onClick={goToTop} to={menu.path}>{menu.menuItem}</Link>
                      menu.type === 'why-history' ? (
                        <Link onClick={handleWhyHistoryRef} to={menu.path}>
                          {menu.menuItem}
                        </Link>
                      ) : (
                        <span>{menu.menuItem}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </Grid>
            ) : (
              <Grid item sm={3} xs={12} className="menu-items" key={index}>
                <h5>{item.heading}</h5>
                <ul>
                  {item.menuItems
                    .filter(items => items.type === 'ceo')
                    .map((menu, i) => (
                      <li key={menu.id}>
                        <a href={menu.path} target="_blank">
                          {menu.menuItem}
                        </a>
                      </li>
                    ))}
                  {item.menuItems
                    .filter(items => items.type !== 'ceo')
                    .map((menu, i) => (
                      <li key={i}>
                        {menu.menuItem === 'Launch Video' ? (
                          <span
                            className="customLinkElm"
                            onClick={handleVideoPopup}
                          >
                            {menu.menuItem}
                          </span>
                        ) : menu.type === 'why-history' ? (
                          <Link onClick={handleWhyHistoryRef} to={menu.path}>
                            {menu.menuItem}
                          </Link>
                        ) : (
                          <Link onClick={goToTop} to={menu.path}>
                            {menu.menuItem}
                          </Link>
                        )}
                      </li>
                    ))}
                  {item.id == 3 && (
                    <Grid item xs={12} className="">
                      <MediaIcons />
                    </Grid>
                  )}
                </ul>
              </Grid>
            )
          )}
          <CustomDialog
            open={open}
            onClose={handleClose}
            className={classes.launchVideoModal}
            data={
              <iframe
                id="launchVideoModal"
                width="560"
                height="315"
                src={videoPause}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            }
          />
        </Grid>
        <Grid container className="footer-social">
          <Hidden smDown>
            <Grid item sm={9} xs={12}>
              <Grid container className="footer-left-column">
                <Grid item sm={3} xs={12}>
                  <DownloadAppScan />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <div className="follow-us-wrapper">
                    <FollowUs />
                    <h6>Follow Us</h6>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item sm={3} xs={12} className="">
            <ServiceByDroom />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
