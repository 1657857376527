import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

import LoginForm from './Login/LoginForm';
import { useHistory } from 'react-router-dom';
import CustomDialog from '../common/CustomDialog';
import SignupForm from './Login/SignupForm';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutUser } from '../../../redux/reducers/loginSlice';
import { useState } from 'react';
import OtpModal from '../Modals/OtpModal';
const useStyles = makeStyles(theme => ({
  mainNavigation: {
    '& li': {
      display: 'inline-block',
      padding: '0 10px'
    },
    '& a': {
      textDecoration: 'none'
    },
    '& .loginBtn': {
      position: 'relative',
      '& > span': {
        color: '#2598fb',
        cursor: 'pointer'
      }
    }
  },
  menuLink: {
    color: '#30343e',
    textDecoration: 'none',
    '&:hover, &:focus': {
      color: '#3ba5e0'
    }
  },
  loginPopup: {
    width: 'auto',
    maxWidth: '350px',
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: '5px',
    padding: '0',

    zIndex: '999',
    '& ul li': {
      display: 'block',
      padding: '0',
      '& span': {
        padding: '10px',
        display: 'block',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        color: '#30343e',
        cursor: 'pointer',
        '&:hover, &:focus': {
          color: '#2598fb'
        }
      }
    },
    [theme.breakpoints.down('sm')]: {}
  }
}));
const menuItem = [
  {
    id: 1,
    page: 'Key Highlights',
    url: '#key-highlights'
  },
  {
    id: 2,
    page: 'About us',
    url: '#about-history'
  },
  {
    id: 3,
    page: 'How it Works',
    url: '#how-it-works'
  },
  {
    id: 4,
    page: 'Know your Number plate',
    url: '#know-number-plate'
  },
];
export default function Navigation(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { authenticated } = useSelector(state => state.auth);
  const { value } = useSelector(state => state.panel);
  const [open, setOpen] = React.useState(false);
  const [isSignup, setIsSignup] = React.useState(false);
  const [otpOpen,setOtpOpen]=useState(false);
  const [userId,setUserId]=useState('');
  const [resendMsg,setResendMsg]=useState('');

  useEffect(() => {
    if (value) {
      handleLoginPopup();
    }
  }, [authenticated, value])
  const goToTop = () => {        
    window.scrollTo(0, 0);
  };
  const handleLoginPopup = () => {
    setOpen(!open);
  }
  const handleResendMsg=(value)=>{
    setResendMsg(value);
  }
  const handleClose = () => {
    setOpen(false);
    setOtpOpen(false);
  };
  const handleSignUpChange = () => {
    setIsSignup(true);
  };
  const handleLoginChange = () => {
    setIsSignup(false);
  };
  const handleOtpChange=(uid)=>{
    setOtpOpen(true);
    setUserId(uid)
  }
  const handleLogout=()=>{
    dispatch(logoutUser());
    alert("logout successfully");
    window.location.reload();
  }
  return (
    <div className={classes.mainNavigation}>
      <ul className="navigation">
        <li>
          <Link to="/" className={classes.menuLink} onClick={goToTop}>Home</Link>
        </li>
        {menuItem.map((item, index) => (
          <li key={index}>
            <a
              className={classes.menuLink}
              href={`/${item.url}`}
              onClick={props.hidemenu}
            >
              {item.page}
            </a>
          </li>
        ))}
        {
          authenticated &&
          <li >
            <Link
              className={classes.menuLink}
              to='/account'>
              Account
            </Link>
          </li>
        }
        <li className="loginBtn">
          {
            !authenticated ?
              <span onClick={handleLoginPopup}>Login</span>
              :
              <span onClick={handleLogout}>Logout</span>

          }
          {
            !authenticated &&
            <>
            {
              !otpOpen?
              <CustomDialog
              open={open}
              onClose={handleClose}
              data={
                !isSignup ?
                <LoginForm changeToSignup={handleSignUpChange} otpModelHandle={handleOtpChange} handleResendMsg={handleResendMsg}/>
                :
                <SignupForm changeToLogin={handleLoginChange}  otpModelHandle={handleOtpChange} handleResendMsg={handleResendMsg} />
              }
              // data={<SignupForm />}
              />:
              <OtpModal open={open} onClose={handleClose} user={userId} email={resendMsg}/>
            }
            </>
          }
        </li>
      </ul>
    </div>
  );
}
