import { configureStore as baseConfigureStore } from '@reduxjs/toolkit';
import { otpSlice } from './reducers/otpSlice';
import { loginSlice } from './reducers/loginSlice';
import { panelSlice } from './reducers/panelSlice';
import { basicDetailSlice } from './reducers/basicDetailSlice';
import { combineReducers } from '@reduxjs/toolkit';

const reducer = combineReducers({
  otp: otpSlice.reducer,
  auth: loginSlice.reducer,
  panel: panelSlice.reducer,
  basicDetail: basicDetailSlice.reducer
});

const configureStore = (initialState = null) => {
  const store = baseConfigureStore({
    reducer,
    ...(initialState && { preloadedState: initialState })
  });

  return store;
};

export default configureStore;
