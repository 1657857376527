import React from 'react';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  needHelpWrapper: {
    '& .support-list': {
      listStyle: 'none',
      margin: '0',
      marginTop: '20px',
      padding: '0',
      fontSize: '15px',
      '& li': {
        display: 'inline-block',
        marginLeft: '20px'
      },
      '& li a': {
        color: '#000',
        textDecoration: 'none'
      },
      '& li:first-child': {
        marginLeft: '0'
      },
      '& i': {
        color: '#6f727b',
        fontSize: '12px',
        marginRight: '5px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
      '& li a': {
        fontSize: '13px'
      }
    }
  }
}));

export default function NeedHelp() {
  const classes = useStyles();
  return (
    <div className={classes.needHelpWrapper}>
      <h6>
        Need Help?
        <span>Don't worry we're just a call away from you.</span>
      </h6>
      <ul className="support-list">
        <li>
          <i className="d-history-telephone"></i>
          <a href="tel:+9650080808">9650 08 08 08 </a>
        </li>
        <li>
          <i className="d-history-message"></i>
          <a href="mailto:support@vehiclehistory.in">
          support@vehiclehistory.in
          </a>
        </li>
      </ul>
    </div>
  );
}
