import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  followUsWrapper: {
    '& ul': {
      listStyle: 'none',
      margin: '0',
      display: 'flex',
      alignContent: 'center',
      '& li a': {
        textDecoration: 'none',
        fontSize: '26px',
        '& > span': {
          height: '33px',
          display: 'block'
        }
      },
      '& li': {
        marginLeft: '10px'
      },
      '& li:first-child': {
        marginLeft: '0'
      }
    },
    [theme.breakpoints.down('sm')]: {}
  }
}));
function IconList(props) {
  const {icons} =props;
  const classes = useStyles();
  return (
    <div className={`${classes.followUsWrapper}`}>
        <ul>
            {icons.map((item, index) => (
            <li key={index}>
            <a href={item.url} target="_blank">
            <span dangerouslySetInnerHTML={{ __html: item.icon }} style={{fontSize:props.size&&props.size}}/>
            </a>
            </li>
            ))}
        </ul>
    </div>
  );
}

export default IconList;
