import { FrontloadProvider } from 'react-frontload';
import Root from '../pages/Root'
import routes from '../routes';

export default function App({ frontloadState, store }) {
  return (
    <FrontloadProvider initialState={frontloadState}>
       <Root routes={routes} store={store} />
    </FrontloadProvider>
  );
}
