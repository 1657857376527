import React from "react";
import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import '../static-pages.scss'

const VehicleOwnerDetails = ()=>{
    return(
        <div id="history-blogs" className="static-page-wrapper">
            <Container className="container">
                <section className="section-wrapper">
                    <h1 className="section-heading">How to Find Vehicle Owner Contact Details by Registration Number?</h1>
                    <p> India has one of the fastest-growing automobile markets in the world. As of 2020, they are the fifth-largest automobile market in the world in terms of new car registrations. Every car bought needs to be registered by its owner. This is a mandatory process. The registration number proves the ownership of the vehicle, the age of the vehicle and most importantly the authenticity of the vehicle. The RTO (Regional Transport Office) has their centralised database for all vehicles registered in India. This makes it easy to track down the details of any vehicle. It is especially effective against rash drivers and hit and run cases. Here we will discuss the ways on how you can get the <a href="/" target="_blank">vehicle owner details</a> by the registration number. </p>
                </section>
                <section className="section-wrapper">
                    <p><b>1.Vehicle history</b> - We have our tool to fetch you the details, while you enter a registration number of any registered motor vehicle. </p>
                    <p>You can easily get the owner details from the website by following these simple steps. </p>
                    <ul style={{paddingLeft:'30px'}}>
                        <li>Visit vehiclehistory.in website</li>
                        <li>Enter the Registration number correctly in the box</li>
                        <li>Click on Check history</li>
                        <li>All details will appear on your screen as submitted to the RTO. You can download the report and even share it. </li>
                    </ul> 

                    <p>What are the details you're going to get? </p>
                    <p>Under the Motor Vehicles Act of 1988, the following details will be available -</p>
                    <ol style={{paddingLeft:'30px'}}>
                        <li>Registration Details</li>
                        <li>Advanced Registration </li>
                        <li>Vehicle Details</li>
                        <li>Blacklist Details</li>
                        <li>Theft History</li>
                        <li>Insurance Details</li>
                        <li>Hypothecation Module</li>
                        <li>Flood Damage History</li>
                        <li>Vehicle Service Logs</li>
                        <li>Purchase Details</li>
                    </ol>
                    
                    <p>
                        <b>2.Details through our official App - </b>Yes, it is possible to get the details through the app. We have our personalised app which will track down the details for you with a single click. And surprisingly we have launched it for both <a href="https://play.google.com/store/apps/details?id=com.droomhistory&amp;hl=en" target="_blank">vehicle owner details</a>
                    </p>
                    <p>
                        Here are the steps -
                    </p>
                    <ul style={{paddingLeft:'30px'}}>                            
                        <li>Install the "Check Vehicle History &amp; Registration" app</li>
                        <li>Have a look at the introduction or skip it, as you wish</li>
                        <li>A similar box will appear like the website where you must enter the correct registration number of the vehicle.</li>
                        <li>Click on the "check now" button</li>
                    </ul>
                    <p>You will get the complete details including the Owner's name, RTO details, Model and Manufacturer, RC/FC expiry and Insurance details etc. </p>
                    <p>Try these steps to get the following details of any registered motor vehicle. </p>
                </section>               
            </Container>
        </div>
    )
}
export default VehicleOwnerDetails;