import React, { useState } from 'react';
import { makeStyles, Dialog, Button, Slide, Hidden } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  modalWrapper: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '480px'
    },
    '& .close-icon': {
      position: 'absolute',
      top: '10px',
      right: '10px',
      cursor: 'pointer'
    }
  },
  modalContentWrapper: {
    padding: '20px 30px',
    fontFamily: '"Cera Pro", sans-serif',
    '& .heading': {
      textAlign: 'center',
      margin: '0',
      fontSize: '24px',
      fontWeight: '500'
    },
    '& .error-message': {
      color: 'red'
    }
  },
  [theme.breakpoints.down('sm')]: {
    modalWrapper: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 'inherit',
        margin: '0',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px'
      },
      '& .MuiDialog-scrollPaper': {
        alignItems: 'end'
      }
    }
  }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomDialog(props) {
  const classes = useStyles();
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`${classes.modalWrapper} ${props.className}`}
    >
      <Hidden mdDown>
        <CloseIcon className="close-icon" onClick={props.onClose} />
      </Hidden>
      <div className={classes.modalContentWrapper}>
        {
          props.modalType === 'press-modal' ?
          props.newsItem
          :
          props.data
        }
        {/* <Button onClick={props.onClose} color="primary">
                    close
                </Button> */}
      </div>
    </Dialog>
  );
}
