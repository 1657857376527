import React from 'react';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  serviceByDroomMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& .droom-logo img': {
      maxWidth: '125px'
    },
    '& span': {
      fontSize: '12px',
      marginRight: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }
}));

export default function ServiceByDroom() {
  const classes = useStyles();
  return (
    <div className={classes.serviceByDroomMain}>
      <span>- a service by</span>
      <figure className="droom-logo">
        <img
          src="https://cdn1.acedms.com/photos/images/web/beta/images/history/footer/droom-logo.png"
          alt="droom logo"
        />
      </figure>
    </div>
  );
}
