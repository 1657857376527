import { useEffect, useState } from 'react';
import { makeStyles, Container } from '@material-ui/core';
import ThankYouHeader from './ThankYouHeader';
import ThankYouFooter from './ThankYouFooter';
import { pdfDownloader } from '@helper/pdfDownloader';
import axios from 'axios';
import NoDataFound from '@loginJourney/components/failure/NoDataFound';
const useStyles = makeStyles(theme => ({
  thankYouWrapper: {
    paddingTop: '30px',
    backgroundColor: '#f5f5f5',
    '& .container': {
      padding: theme.spacing(2, 1.25),
      maxWidth: '1170px',
      ['@media(min-width: 1370px)']: {
        maxWidth: '1280px'
      }
    },
    '& .content-wrapper': {
      backgroundColor: '#fff',
      padding: '20px'
    },
    '& .download-report-block': {
      margin: '0 auto 20px',
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0',
      '& .content-wrapper': {
        margin: '0 -10px'
      }
    }
  }
}));

export default function ThankYou() {
  const classes = useStyles();
  const [urlParams, setUrlParams] = useState({
    oid: '',
    action: ''
  });
  const [dataFound, setDataFound] = useState(true);
  const getUrlData = () => {
    const search = window.location.search;
    const urlParameter = new URLSearchParams(search);
    setUrlParams({
      oid: urlParameter.get('oid'),
      action: urlParameter.get('action')
    });
  };
  const getData = async () => {
    if (urlParams.oid != '') {
      await axios
        .get(`/drapi/reports/download?oid=${urlParams.oid}&api_token=`)
        .then(res => {
          if (
            res.data.data.code == 'failed' &&
            res.data.data.error_code == 404
          ) {
            setDataFound(false);
          }
        });
    }
  };
  useEffect(() => {
    getData();
    getUrlData();
  }, [urlParams.oid, urlParams.action, dataFound]);
  const downloadHandler = async () => {
    await axios
      .get(`/drapi/reports/download?oid=${urlParams.oid}&api_token=`)
      .then(res => {
        if (res.data.data.code === 'success') {
          const downloaLink = res.data.data.data.api_url;
          const pdfName = res.data.data.data.name;
          pdfDownloader(downloaLink, pdfName);
        }
      });
  };
  return (
    <div className={classes.thankYouWrapper}>
      <Container maxWidth="lg" className="container">
        {dataFound ? (
          <ThankYouHeader downloadHandler={downloadHandler} />
        ) : (
          <NoDataFound />
        )}

        <div className="content-wrapper">
          <div className="footer">
            <ThankYouFooter />
          </div>
        </div>
      </Container>
    </div>
  );
}
