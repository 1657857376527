import React from "react";
import { Container } from '@material-ui/core';
import './static-pages.scss'
const Methodology = () =>{
    return(
        <div id="history-methodology" className="static-page-wrapper">
            <Container className="container">
                <section className="section-wrapper">
                    <h1 className="section-heading">History Methodology</h1>
                    <p>History is the most advanced and comprehensive vehicle history check based on the true facts. The used Indian automobile industry is full of greys, where trust and vehicle authenticity is the major issue. Vehicle History report informs buyers about the background of a used vehicle.</p>

                    <p>Finding out used vehicle's history, just by seeing is not possible, History uses complex algorithmic approach and data science methodologies to fetch the crucial historic data for a pre-owned vehicle, which is presented in a form of a comprehensive report.</p>

                    <p>It takes vehicle's registration number as an input to generate a valid vehicle history report for that vehicle. Data in real time is consolidated as an output report with various important parameters like registration details, loan details, blacklist details, ownership details and many other important points. This output report can be viewed on the same page, and is sent to user's email id.</p>

                    <p>History reports are available for wide range of vehicles including cars, scooters, bikes and more. History has data for more than 19 crore registered vehicles in more than 1000 Regional Transport Offices across the nation. This data is consolidated from multiple authoritative sources with more than 60 lakh vehicles' data updated in real time. This data is processed using advance algorithms to generate desirable output.</p>

                    <p>Any vehicle running on the Indian roads might have met an accident (minor or major) or has some liabilities attached or more. History considers all the important facts to give user the best possible knowledge about the vehicle's history to make an informed decision.</p>
                </section>
            </Container>
        </div>

    )
}
export default Methodology;