import React, { useEffect, useState } from "react";
import { makeStyles, Container, Grid, Paper } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import '../static-pages.scss'
import {data as pressData} from './data';
const useStyles = makeStyles(theme =>({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
}));

function PressOffline(props){
    const classes = useStyles();
    const {onReadMoreModal:handlePressPopup} = props;
    const [msiteView, setMsiteView] = useState(false);
    useEffect(()=>{
        const handleResize = () =>{
            if(window.innerWidth < 767){
                setMsiteView(true);
            }else{
                setMsiteView(false);
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return()=>{
            window.removeEventListener('resize', handleResize);
        }
    })
    return(
        <div id="press-offline">
            <Container className="container" maxWidth="lg">
                <Grid container spacing={2} alignItems="left" justifyContent="left">
                    {
                        pressData.map((dataItem)=>(
                            dataItem.id ==='offline' &&
                            dataItem.onlinePressDataItem.map((pressItem) =>(
                                <Grid key={pressItem.id} item sm={6} xs={12} className={props.className}> 
                                    <Paper className="box">
                                        <span className="date"><AccessTimeIcon /> {pressItem.date}</span>
                                        <h2 className="press-title">{pressItem.title}</h2>
                                        <figure>
                                            <img src={pressItem.imgUrl} alt={pressItem.alt} />
                                        </figure>
                                        <span className="read-more-btn" onClick={() => handlePressPopup(pressItem)}>Read More <ArrowForwardIosIcon /></span>
                                    </Paper>
                                </Grid>
                            ))
                        ))
                    }
                    
                </Grid>
            </Container>
        </div>
    )
}
export default PressOffline;