import React from 'react';
import { makeStyles, Hidden } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles(theme => ({
  stepsWrapper: {
    backgroundColor: '#eef9ff',
    padding: '10px',
    position:'relative',
    '& ul': {
      listStyle: 'none',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      margin: 'auto',
      maxWidth: '90%',
      textAlign: 'center',
      position: 'relative',
      '& li': {
        padding: '0 10px',
        width: '33%',
        textAlign: 'center',
        counterIncrement: 'item',
        color: '#6f727b'
      },
      '& li:before': {
        display: 'block',
        listStyleType: 'decimal',
        content: 'counter(item)',
        width: '30px',
        height: '30px',
        backgroundColor: '#fff',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #3ba5e0',
        color: '#3ba5e0',
        margin: 'auto',
        position: 'relative',
        zIndex: '1',
        marginBottom: '10px'
      },
      '& li.active-step, & li.completed': {
        color: '#30343e'
      },
      '& li.active-step:before': {
        backgroundColor: '#4da424',
        borderColor: '#4da424',
        color: '#fff'
      },
      '& li.completed-step:before': {
        backgroundColor: '#3ba5e0',
        borderColor: '#3ba5e0',
        color: '#fff',
        content: '" \\e90a "',
        fontFamily: 'history-icomoon',
        fontSize: '12px'
      }
    },
    '& ul:before': {
      height: '1px',
      backgroundColor: '#3ba5e0',
      width: '65%',
      position: 'absolute',
      top: '15px',
      content: '" "',
      left: '0',
      right: '0',
      margin: 'auto'
    },
    '& .back-btn': {
      position:'absolute',
      top:'25px',
      left:'20px',
      fontSize: '30px',
      cursor:'pointer'
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex'
    }
  }
}));
const stepsData = [
  {
    id: 1,
    name: 'Address'
  },
  {
    id: 2,
    name: 'Payment Option'
  },
  {
    id: 3,
    name: 'Review & Pay'
  }
];
export default function Steps(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState('active-step');
  const [compleStep, setCompleStep] = React.useState('completed-step');
  // console.log(props);

  return (
    <div className={classes.stepsWrapper}>

        {/* <Hidden mdUp> */}
      { props.stateIndex>0&&
          <ArrowBackIosIcon className="back-btn" onClick={props.handlePaymentStep}/>
        }
      {/* </Hidden> */}
      <ul>
        {stepsData.map((item, index) => (
          index<props.stateIndex?
          <li
            key={index}
            className={compleStep}
          >
            <span>{item.name}</span>
          </li>:
          <li
          key={index}
          className={props.stateIndex == index ? activeStep : ''}
        >
          <span>{item.name}</span>
        </li>

        ))}
      </ul>
    </div>
  );
}
