import React from "react";
import AccessTimeIcon from '@material-ui/icons/AccessTime';

function ReadMoreData(props){
    return(
        <div>
            <span className="date"><AccessTimeIcon /> {props.pressItemData.date}</span>
            <h2 className="press-title">{props.pressItemData.title}</h2>
            <figure>
                <img src={props.pressItemData.imgUrl} alt={props.pressItemData.alt} />
            </figure>
            <div className="press-content" dangerouslySetInnerHTML={{__html: props.pressItemData.content}} />
        </div>
    )
}
export default ReadMoreData;