import React from 'react';
import { makeStyles, Grid, Button, Hidden } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  bankVerfiedWrapper: {
    '& .security-wrapper.msite-container': {
      padding: '20px',
      paddingLeft: '30px',
      paddingRight: '30px',
      '& p': {
        marginTop: '0',
        fontSize: '13px'
      },
      '& .verified-icon': {
        fontSize: '40px'
      },
      '& .bank-verified': {
        paddingLeft: '0',
        '& ul': {
          listStyle: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          '& li': {
            width: '30%',
            display: 'inline-block',
            '& figure': {
              margin: '0'
            },
            '& img': {
              maxWidth: '90%'
            }
          }
        }
      }
    }
  }
}));

export default function BankVerfied() {
  const classes = useStyles();
  return (
    <Hidden mdUp>
      <div className={classes.bankVerfiedWrapper}>
        <div className="security-wrapper msite-container">
          <Grid container spacing={2}>
            <Grid item xs={2} className="verified-icon">
              <i className="d-history-shield">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
                <span className="path6"></span>
              </i>
            </Grid>
            <Grid item xs={10} className="bank-verified">
              <p>
                Note: Making Payment on droom.in is 100% safe. Your transaction
                is processed through a secure https internet connection based on
                secure socket layer technology.
              </p>
              <ul>
                <li>
                  <figure>
                    <img
                      src="https://cdn1.acedms.com/photos/images/web/beta/images/history/payment-option/visa.png"
                      alt="visa verified"
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <img
                      src="https://cdn1.acedms.com/photos/images/web/beta/images/history/payment-option/master-card.png"
                      alt="master card"
                    />
                  </figure>
                </li>
                <li>
                  <figure>
                    <img
                      src="https://cdn1.acedms.com/photos/images/web/beta/images/history/payment-option/rupay.png"
                      alt="Rupay"
                    />
                  </figure>
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>
      </div>
    </Hidden>
  );
}
