import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { decodeData } from '../../../../helper/encoderDecoder';
import { useSelector } from 'react-redux';
const useStyles = makeStyles(theme => ({
  paymentMethodWrapper: {}
}));
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
const paymentOptions = [
  {
    id: '1',
    name: 'upi',
    value: 'UPI (Droom Recommended, Fast & secure)'
  },
  {
    id: '2',
    name: 'net_banking',
    value: 'Net Banking'
  },
  {
    id: '3',
    name: 'debit_card',
    value: 'Debit Card'
  },
  {
    id: '4',
    name: 'credit_card',
    value: 'Credit Card'
  }
];
export default function PaymentMethod() {
  const { payMethod } = useSelector(state => state.basicDetail);
  return (
    <div className="detail-block">
      <CheckCircleIcon className="green-check-icon" />
      {paymentOptions
        .filter(item => item.name === payMethod)
        .map(filterName => (
          <div className="detail-panel" key={filterName.id}>
            {filterName.value}
          </div>
        ))}
    </div>
  );
}
