import { makeStyles, Container } from '@material-ui/core';
import OrderHistory from './OrderHistory';
import UserDetail from './UserDetail';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const useStyles = makeStyles(theme => ({
  myAccountWrapper: {
    paddingTop: '30px',
    backgroundColor: '#f5f5f5',
    '& .content-wrapper': {
      backgroundColor: '#fff',
      padding: '20px 30px'
    },
    '& .container': {
      padding: theme.spacing(2, 1.25),
      maxWidth: '1170px',
      ['@media(min-width: 1370px)']: {
        maxWidth: '1280px'
      }
    },
    '& .main-heading': {
      color: '#30343e',
      fontSize: '22px',
      fontWeight: '600',
      margin: '0'
    },
    '& .sub-heading': {
      color: '#30343e',
      marginTop: '0',
      position: 'relative',
      fontSize: '20px',
      fontWeight: '500',
      marginBottom: '0'
    },
    '& .pagination-block': {
      textAlign: 'center',
      marginTop: '20px',
      '& ul': {
        display: 'flex',
        margin: 'auto',
        width: 'auto',
        maxWidth: 'auto',
        overflow: 'auto',
        justifyContent: 'center',
        listStyle: 'none',
        '& li': {
          padding: '5px 11px',
          cursor: 'pointer',
          color: '#605f5f'
        },
        '& li:hover': {
          color: '#000'
        },
        '& li.active': {
          backgroundColor: '#2598fb',
          color: '#fff',
          borderRadius: '50%'
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '10px',
      '& .container': {
        paddingLeft: '0',
        paddingRight: '0'
      },
      '& .content-wrapper': {
        padding: '10px'
      }
    }
  }
}));

export default function MyAccount() {
  const classes = useStyles();
  const { authenticated, user } = useSelector(state => state.auth);
  const [orderDetails, setOrderDetails] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const goToTop = () => {
    window.scrollTo(200, 200);
  };
  const pageHandler = pageNo => {
    setCurrPage(pageNo);
    goToTop();
  };
  const getAccountData = async () => {
    if (authenticated) {
      const { data } = await axios.get(
        `drapi/account-history?page=${currPage}&api_token=`
      );
      if (data.data.code === 'success') {
        setTotalPage(data.data.data.last_page);
        setOrderDetails(data.data.data.data);
      }
    }
  };
  useEffect(() => {
    getAccountData();
    goToTop();
  }, [currPage]);
  return (
    <div className={classes.myAccountWrapper}>
      <Container maxWidth="lg" className="container">
        <div className="content-wrapper">
          <div className="msite-container">
            <h2 className="main-heading">Welcome to History Account</h2>
          </div>
          <UserDetail userD={user} />
          <div className="msite-container">
            <h3 className="sub-heading">Order History</h3>
          </div>
          <OrderHistory orderD={orderDetails} />
        </div>
        <div className="pagination-block">
          <ul>
            {Array.from(Array(totalPage).keys(), (e, index) => {
              return (
                <div key={index}>
                  {index + 1 == currPage ? (
                    <li
                      key={index}
                      className={'active'}
                      onClick={() => pageHandler(index + 1)}
                    >
                      {index + 1}
                    </li>
                  ) : (
                    <li key={index} onClick={() => pageHandler(index + 1)}>
                      {index + 1}
                    </li>
                  )}
                </div>
              );
            })}
          </ul>
        </div>
      </Container>
    </div>
  );
}
