import React, { useState, useEffect } from 'react';
import { makeStyles, Button, Dialog, Slide } from '@material-ui/core';
import { useTimer } from 'react-timer-hook';
import { loginUser } from '../../../redux/reducers/loginSlice';
import OtpInput from 'react-otp-input';
import ErrorIcon from '@material-ui/icons/Error';
import { useSelector, useDispatch } from 'react-redux';
import { sendOtp } from '../../../redux/reducers/otpSlice';
const useStyles = makeStyles(theme => ({
  otpModal: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '480px'
    }
  },
  otpModalWrapper: {
    padding: '20px 30px',
    fontFamily: '"Cera Pro", sans-serif',
    '& .heading': {
      textAlign: 'center',
      margin: '0',
      fontSize: '24px',
      fontWeight: '500'
    },
    '& p': {
      color: '#6f727b'
    },
    '& .otp-input-wrapper': {
      marginBottom: '20px'
    },
    '& .otp-input-wrapper.error':{
      '& input':{
        border:'1px solid #ff0000!important',
        background: '#fff2f3',
      }
    },
    '& .error-message': {
      display:'flex',
      marginTop:'10px',
      color: 'red',
      fontSize:'14px',
      '& svg':{
        height:'17px'
      }
    }
  },
  otpResend: {
    '& a': {
      color: '#3ba5e0'
    },
    color: '#3ba5e0'
  },
  resendBtn: {
    display: 'block',
    color: '#2598fb',
    fontSize: 14,
    fontWeight: '500',
    margin:'auto',
    cursor: 'pointer'
  },
  [theme.breakpoints.down('sm')]: {
    otpModal: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 'inherit',
        margin: '0',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px'
      },
      '& .MuiDialog-scrollPaper': {
        alignItems: 'end'
      }
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OtpModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { error } = useSelector(state => state.auth)
  const [code, setCode] = useState('');
  const [otpErrorMessage, setOtpErrorMessage] = React.useState('');
  const [otpErrorCss,setOtpErrorCss]=useState('otp-input-wrapper ')
  const [counter, setCounter] = useState(10);
  const handleChange = (code) => {
    setOtpErrorMessage("");
    setOtpErrorCss('otp-input-wrapper');
    setCode(code)
  };

  const submitHandler = async e => {
    const dataSend = {
      userId: props.user,
      code: code
    }
    dispatch(loginUser(dataSend));
  };

  useEffect(() => {
    if (code.length == 4) {
      submitHandler();
    }
    if (code.length == 4 && error) {
      setOtpErrorCss('otp-input-wrapper error');
      setOtpErrorMessage('Incorrect Password');
    }
  }, [code, error]);
  var timeNow = new Date();
  timeNow.setSeconds(timeNow.getSeconds() + 60); // 10 minutes timer
  // const [timerNew, setTimerNew] = React.useState(timeNow);
  // const { seconds, minutes, restart } = useTimer({
  //   timerNew,
  //   onExpire: () => {
  //     console.warn('onExpire called');
  //     setResendDisableBtn(false);
  //   }
  // });
  // console.log(timeNow)
  
  useEffect(()=>{
    if(counter > 0){
      setTimeout(()=> setCounter(counter - 1), 1000)
    }
  }, [counter])

  const resendBtn = () =>{
    dispatch(sendOtp(props.email))
    setCounter(30);
    const parentDiv = document.querySelector('.otp-input-wrapper');
    const childDiv = parentDiv.children[0].children[0].children[0];
    childDiv.focus();
  }

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.otpModal}
    >
      <div className={classes.otpModalWrapper}>
        <h4 className="heading">We have Sent you an OTP</h4>
        <p>
          enter the 4 digit OTP <br />
          sent on <strong>{props.userData}</strong> to Procced
        </p>
        <div className={otpErrorCss}>
          <OtpInput
            value={code}
            onChange={handleChange}
            numInputs={4}
            separator={<span style={{ width: '8px' }}></span>}
            isInputNum={true}
            shouldAutoFocus
            autoFocus={true}
            inputStyle={{
              border: '1px solid #a2adbd',
              borderRadius: '8px',
              width: '100%',
              height: '90px',
              fontSize: '24px',
              color: '#000',
              fontWeight: '400',
              caretColor: 'blue'
            }}
            focusStyle={{
              border: '1px solid #000',
              outline: 'none'
            }}
          />
          {
            otpErrorMessage&&
          <span className="error-message"><ErrorIcon />{otpErrorMessage}</span>
          }
        </div>
        
          <div style={{textAlign:'center'}}>
            {
              counter > 0 ?
              <p>Resend in {counter} seconds</p>
              :
              <p className="">
                Didn't receive an OTP? <br />
                <span className={classes.otpResend}>
                  <Button className={classes.resendBtn} onClick={resendBtn}>Resend</Button> 
                </span>
              </p>
            }
          </div>
        {/* <Button onClick={props.onClose} color="primary">
          close
        </Button> */}
      </div>
    </Dialog>
  );
}
