import React, { useState } from "react";
import { makeStyles, Grid, Button, TextField } from "@material-ui/core"
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme =>({
    userDetailsWrapper:{
        marginTop:20,
        marginBottom:30,
        '& .heading-panel':{
            display:'flex',
            '& h2 + div':{
                position:'relative',
                marginLeft:'20px'
            }
        },
        '& .sub-heading': {
            fontWeight: '400!important',
        },
        '& .user-image':{
            '& figure':{
                margin:'0',
                display:'inline-block',
                position:'relative',
            },
            '& img':{
                width:'auto',
                maxHeight:'92px'
            }
        },
        '& .user-detail':{
            '& p':{
                marginBottom:0,  
                marginTop:'10px',
                color:'#30343e' 
            },
            '& p:first-child':{
                marginTop:0,
            }
        },
        '& .save-edit-btn':{
            fontSize:'14px',
            color:'#2598fb',
            padding:'0',
            marginLeft:'20px',
            '& svg':{
                width:'16px',
            }
        },
        '& .save-btn':{
            borderRadius:'20px',
            backgroundColor:'#2598fb',
            color:'#fff'
        },
        '& .edit-name-input':{
            '& input':{
                padding:'0'
            }
        },
        [theme.breakpoints.down('sm')]:{
            '& .user-image img':{
                maxWidth:'100px',
                maxHeight:'145px'
            }
        }
    }
}))
export default function UserDetail({userD}){
    const classes = useStyles();
    const [editSaveBtnText, setEditSaveBtnText] = useState(true)
    const [nameValue, setNameValue] = useState(userD.name);
    const handleSaveEditBtn = () => {
        setEditSaveBtnText(!editSaveBtnText);
    };
    const handleSaveEditText = (e) =>{
        setNameValue(e.target.value);
    }
    return(
        <div className={classes.userDetailsWrapper}>
            <Grid container spacing={2}>
                <Grid item xs={4} sm={2} className="user-image">
                    <figure>
                        <img
                        src={`${process.env.IMG_URL}/photos/images/web/beta/images/history/user-account/user-icon.svg`}
                        alt="user image"
                        />
                    </figure>
                </Grid>
                <Grid item xs={8} sm={10} className="user-detail">
                    <div className="heading-panel">
                        {
                            editSaveBtnText ? 
                            <><h2 className="sub-heading">{nameValue}</h2></>
                            :
                            <TextField id="editSaveNameInput" onChange={handleSaveEditText} color="secondary" className="edit-name-input" value={nameValue} autoFocus={true} />
                        }
                        {/* <Button color="primary" className={`save-edit-btn ${editSaveBtnText ? 'edit-btn' : 'save-btn'}`} onClick={handleSaveEditBtn}>
                            {
                                editSaveBtnText ? 
                                <><EditIcon /> Edit</>
                                :
                                'Save'
                            }
                        </Button> */}
                    </div>
                    <p>{userD.email}</p>
                    <p>+91 {userD.mobile}</p>
                </Grid>
            </Grid>
        </div>
    )
}