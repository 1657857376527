import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { sendOtp } from '@redux/reducers/otpSlice';
import { useDispatch, useSelector } from 'react-redux';
const useStyles = makeStyles(() => ({
  loginFormWrapper: {
    fontFamily: '"Cera Pro", sans-serif',
    color: '#a2adbd',
    '& .heading': {
      margin: '0 0 10px',
      fontWeight: '500',
      color: '#30343e'
    },
    '& .mainBtn': {
      padding: '10px',
      borderRadius: '5px',
      textTransform: 'inherit',
      fontSize: '16px',
      fontWeight: '500'
    },
    '& .submitBtn': {
      backgroundColor: '#2598fb',
      color: '#fff'
    },
    '& .signupBtn': {
      textTransform: 'inherit',
      fontWeight: '600',
      color: '#3ba5e0'
    },
    '& .facebookLoginBtn': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      color: '#a2adbd',
      border: '1px solid #dddddd',
      '& i': {
        marginRight: '5px'
      }
    },
    '& .facebookLoginBtn:hover': {
      backgroundColor: '#f7f7f7'
    },
    '& a': {
      color: '#2598fb'
    },
    '& p': {
      textAlign: 'center'
    },
    '& .MuiButton-contained.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    },
    '& .devider': {
      position: 'relative',
      textAlign: 'center',
      margin: '30px',
      color: '#a2adbd',
      '& span': {
        backgroundColor: '#fff',
        padding: '0 10px',
        position: 'relative',
        zIndex: '2'
      }
    },
    '& .devider:before': {
      content: '" "',
      borderTop: '1px dashed #a2adbd',
      width: '100%',
      display: 'block',
      position: 'absolute',
      zIndex: '1',
      top: '50%'
    }
  }
}));

export default function LoginForm(props) {
  const classes = useStyles();

  const [message, setMessage] = useState('');
  const [enableButton, setEnableButton] = useState('');
  const [errorFlag, setErrorFlag] = useState(false);

  const dispatch = useDispatch();
  const { otpSended, error } = useSelector(state => state.otp);

  const inputRef = useRef();

  useEffect(() => {
    if (message.autofocus) {
      inputRef.current.focus();
    }
    if (error) {
      setErrorFlag(true);
    }
  }, [message.autofocus, inputRef, otpSended, error]);

  const submitHandler = async e => {
    e.preventDefault();
    if (error) setErrorFlag(true);
    props.handleResendMsg(message);
    dispatch(sendOtp(message)).then(res => {
      if (res.payload.data.code === 'success') {
        props.otpModelHandle(res.payload.data.data._id);
      }
    });
  };

  const handleChange = event => {
    setErrorFlag(false);
    setMessage(event.target.value);
    if (event.target.value.length > 9) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  };

  return (
    <>
      <div className={`${classes.loginFormWrapper} login-form`}>
        <form>
          <h3 className="heading">Login To History</h3>
          <TextField
            label="Email/Mobile"
            id="emailorMobile"
            helperText="OTP will be sent to your registered email and mobile number."
            margin="normal"
            variant="outlined"
            type="text"
            autoFocus={true}
            fullWidth
            inputRef={inputRef}
            value={message}
            onChange={handleChange}
          />
          <Button
            className="submitBtn"
            variant="contained"
            color="primary"
            disabled={!enableButton}
            fullWidth
            onClick={submitHandler}
          >
            Send OTP
          </Button>
          {errorFlag && <p style={{ color: 'red' }}>{error}</p>}
          <p>
            Don't have an account?
            <Button onClick={props.changeToSignup}>Sign Up</Button>
          </p>
        </form>
      </div>
    </>
  );
}
