import { makeStyles, Grid, Button, Hidden } from '@material-ui/core';
import { decodeData } from '../../../../helper/encoderDecoder';
import axios from 'axios';
import { pdfDownloader } from '../../../../helper/pdfDownloader';
const useStyles = makeStyles(theme => ({
  orderHistoryWrapper: {
    border: '1px solid #b8e6ff',
    borderRadius: '5px',
    marginBottom: '30px',
    marginTop: '10px',
    '& .order-history-header': {
      backgroundColor: '#e5f3ff',
      padding: '10px 20px'
    },
    '& .text-gray-light': {
      color: '#6f727b',
      fontSize: '12px'
    },
    '& .order-history-body': {
      padding: '20px',
      '& .body-container': {
        display: 'flex',
        alignItems: 'center'
      }
    },
    '& figure': {
      margin: '0',
      '& img': {
        maxWidth: '100%'
      }
    },
    '& .vehicle-details': {
      listStyle: 'none',
      margin: '0 0 30px',
      '& li': {
        marginTop: '20px',
        fontSize: '20px',
        color: '#6f727b',
        '& strong': {
          fontWeight: '600',
          color: '#191a1b'
        },
        '& span': {
          width: 'auto',
          minWidth: '150px',
          display: 'inline-block'
        }
      },
      '& li:first-child': {
        marginTop: '0'
      }
    },
    '& .button-group': {
      '& a:first-child, & button:first-child': {
        marginLeft: '0'
      }
    },
    '& .button': {
      borderRadius: '20px',
      border: '1px solid #2598fb',
      padding: '5px 25px',
      marginLeft: '20px',
      boxShadow: 'none',
      color: '#2598fb',
      fontWeight: '600',
      textTransform: 'none'
    },
    '& .primaryButton': {
      backgroundColor: '#2598fb',
      color: '#fff',
      fontWeight: '600'
    },
    '& .actionButton:hover': {
      backgroundColor: '#2598fb',
      color: '#fff'
    },
    [theme.breakpoints.down('xs')]: {
      '& .button-group': {
        '& a': {}
      },
      '& .primaryButton, & .button': {
        color: '#2598fb',
        border: 'none',
        backgroundColor: 'inherit',
        marginLeft: '10px',
        padding: '0'
      }
    }
  }
}));
export default function OrderHistory(props) {
  const imgUrl = `${process.env.IMG_URL}/photos/images/web/beta/images/history/sample-reports/order-history.png`;
  const classes = useStyles();
  const downloadHandler = async oid => {
    const { data } = await axios.get(
      `/drapi/reports/download?oid=${oid}&api_token=`
    );
    if (data.data.code === 'success') {
      const downloaLink = data.data.data.api_url;
      const pdfName = data.data.data.name;
      pdfDownloader(downloaLink, pdfName);
    }
  };
  return (
    <>
      {props.orderD.map((item, index) => (
        <div className={classes.orderHistoryWrapper} key={index}>
          <div className="order-history-header">
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Hidden xsDown>
                  <strong style={{ fontWeight: '500' }}>
                    Report {index + 1}
                  </strong>
                </Hidden>
                <Hidden smUp>
                  <span className="text-gray-light">Order ID: </span>
                  <strong style={{ fontWeight: '500', fontSize: '12px' }}>
                    {item.order_number}
                  </strong>
                </Hidden>
              </Grid>
              <Grid item xs={7} sm={6} style={{ textAlign: 'right' }}>
                <span className="text-gray-light">Order Date: </span>{' '}
                <strong style={{ fontWeight: '500', fontSize: '10px' }}>
                  {item.created_at}
                </strong>
              </Grid>
            </Grid>
          </div>
          <div className="order-history-body">
            <Grid container spacing={2} className="body-container">
              <Grid item xs={4} sm={3}>
                <figure>
                  <img src={imgUrl} alt="user image" />
                </figure>
              </Grid>
              <Grid item xs={8} sm={9}>
                <Hidden xsDown>
                  <ul className="vehicle-details">
                    <li>
                      <span>Reg. Number:</span>
                      <strong>{item.registration_number}</strong>
                    </li>
                    <li>
                      <span>Order ID:</span>
                      <strong>{item.order_number}</strong>
                    </li>
                  </ul>
                </Hidden>
                <Hidden smUp>
                  <p>
                    <strong style={{ fontWeight: '400' }}>
                      Vehicle History Report
                    </strong>
                  </p>
                  <p>
                    <strong style={{ fontWeight: '500' }}>
                      {item.registration_number}
                    </strong>
                  </p>
                </Hidden>
                <div className="button-group">
                  {/* <Button variant="contained" color="primary" href="#view" className="button primaryButton">
                                    View
                                </Button> */}
                  <Button
                    variant="outlined"
                    color="primary"
                    href="#"
                    className="button outlinedButton primaryButton"
                    onClick={() => {
                      downloadHandler(item._id);
                    }}
                  >
                    Download
                  </Button>
                  {/* <Button variant="outlined" color="primary" href="#email" className="button outlinedButton actionButton">Email</Button> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      ))}
    </>
  );
}
