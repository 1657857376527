import React from 'react'
import IconList from '../common/IconList'
const mediaIcons = [
    {
      id: 1,
      icon: '<i class="d-history-facebook"><span class="path1"></span><span class="path2"></span></i>',
      alt: 'facebook',
      url: 'https://www.facebook.com/DroomHistory/'
    },
    {
      id: 2,
      icon: '<i class="d-history-twitter"><span class="path1"></span><span class="path2"></span></i>',
      alt: 'twitter',
      url: 'https://twitter.com/droom_history'
    },
  
  ];
const MediaIcons = () => {
  return (
    <div><IconList icons={mediaIcons} size={'40px'}/></div>
  )
}

export default MediaIcons