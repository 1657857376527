import axios from "axios";
const pdfDownloader=(downloadLink,pdfName)=>{
    axios.get(downloadLink,{responseType:'blob'})
    .then(res=>res.data)
    .then(blob=>{
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download',pdfName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
}

export {pdfDownloader};