import { withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';

export function Root({ routes, store }) {
  return (
    <Provider store={store}>
      <App routes={routes} />
    </Provider>
  );
}

export default withRouter(Root);
