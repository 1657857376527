import React from "react";
import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import './static-pages.scss'

const Blogs = ()=>{
    return(
        <div id="history-blogs" className="static-page-wrapper">
            <Container className="container">
                <section className="section-wrapper">
                    <h1 className="section-heading">Blog</h1>
                    <Button variant="contained" color="primary" href="/" style={{float:'right', color:'#fff', backgroundColor:'#2598fb'}}>Check Car History</Button>
                </section>
                <section className="section-wrapper">
                    <h2 className="sub-heading">
                        <a href="/blog/check-vehicle-details-online-on-history">Check Vehicle Details Online on history</a>
                    </h2>
                    <p>History has the largest vehicle details online database in India with information on over twenty two crore vehicles across the country. The website contains vehicle history data and other related information on its database, making it the biggest open source platform for verified vehicle owners and Vehicle Details. You can get up to 50 different history records and vehicle details within ten seconds. ...</p>
                    <a href="/blog/check-vehicle-details-online-on-history">Continue Reading</a>
                </section>
                <section className="section-wrapper">
                    <h2 className="sub-heading">
                        <a href="/blog/how-to-get-vahan-information-online">How to get Vahan Information Online?</a>
                    </h2>
                    <p>Gone are the days when we had to visit regional transport offices (RTOs) and district transport offices (DTOs) to get vehicle owner details or vehicle registration details. Unfortunately, hit and run cases are more than usual. Even if you manage to capture the vehicle’s registration number, you might not be able to find its details easily....</p>
                    <a href="/blog/how-to-get-vahan-information-online">Continue Reading</a>
                </section>
                <section className="section-wrapper">
                    <h2 className="sub-heading">
                        <a href="/blog/how-to-find-vehicle-owner-contact-details-by-registration-number">How to Find Vehicle Owner Contact Details by Registration Number?</a>
                    </h2>
                    <p>India has one of the fastest-growing automobile markets in the world. As of 2020, they are the fifth-largest automobile market in the world in terms of new car registrations. Every car bought needs to be registered by its owner. This is a mandatory process. ...</p>
                    <a href="/blog/how-to-find-vehicle-owner-contact-details-by-registration-number">Continue Reading</a>
                </section>
            </Container>
        </div>
    )
}
export default Blogs;