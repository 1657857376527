import React from 'react';
import { Container, Grid } from '@material-ui/core';
import './static-pages.scss'
export default function WhatHistory() {
  return (
    <div id="what-history" className="static-page-wrapper">
      <Container className="container">
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="flex-start"
        >
          <h1 className="section-heading">What is History?</h1>
          <p className="text-left">History is India's most comprehensive Vehicle History Check that ensures a buyer about the background of a used vehicle and gives information on various important parameters that a buyer should know like registration details, loan details, blacklist details, ownership details and many other important points.</p>
          <p>The information offered by History report is useful for both buyers and sellers while choosing one among the wide range of used vehicles. History addresses various issues related to a vehicle's safety, trust, and value. The details mentioned in the report offer crucial insights while you are purchasing a second-hand vehicle, as the pre-owned automobile market is full of greys. It helps buyers in making a more informed decision, while giving security for investment and peace of mind.</p>
          <p>History has data for more than 19 crore registered vehicles in more than 1000 Regional Transport Office across the country. This data has been consolidated from multiple authoritative sources with more than 60 lakh vehicle's data updated in real time.</p>
          <p>Data provided by History enables a buyer to take a more informed decision, and not to get trapped by a misleading knowledge given by a seller at the time of selling. In such cases, buyer feels cheated after making the purchase, as they get to know about the fabricated details provided. Thus, it is quintessential to check the background of a used vehicle before investing in it.</p>
        </Grid>
      </Container>
    </div>
  );
}
