import React from 'react';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  downloadAppWrapper: {
    '& .app-scanner': {
      marginBottom: '10px'
    },
    '& figure': {
      margin: '0',
      textAlign: 'left',
      '& img': {
        maxWidth: '70px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '30px'
    }
  }
}));

export default function DownloadAppScan() {
  const classes = useStyles();
  return (
    <div className={classes.downloadAppWrapper}>
      <figure className="app-scanner">
        <img
          src={`${process.env.IMG_URL}/photos/images/web/beta/images/history/footer/app-scan.png`}
          alt="app scan"
        />
      </figure>
      <h6>Download the App</h6>
    </div>
  );
}
