import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import FollowUs from '../../../Components/Footer/FollowUs';
import NeedHelp from './NeedHelp';
const useStyles = makeStyles(theme => ({
  footerWrapper: {
    paddingTop: '20px',
    '& h6': {
      margin: '0',
      fontSize: '20px',
      fontWeight: '500',
      '& span': {
        display: 'block',
        color: '#6f727b',
        fontSize: '14px'
      }
    }
  }
}));

export default function ThankYouFooter() {
  const classes = useStyles();
  return (
    <div className={classes.footerWrapper}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9}>
          <NeedHelp />
        </Grid>
        {/* <Grid item xs={12} sm={3}>
          <h6 style={{ fontSize: '14px', marginBottom: '5px' }}>Follow Us</h6>
          <FollowUs />
        </Grid> */}
      </Grid>
    </div>
  );
}
