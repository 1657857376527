import React from "react";
import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import '../static-pages.scss'

const CheckVechicleDetails = ()=>{
    return(
        <div id="history-blogs" className="static-page-wrapper">
            <Container className="container">
                <section className="section-wrapper">
                    <h1 className="section-heading">Check Vehicle Details Online on history</h1>
                    <Button variant="contained" color="primary" href="/" style={{float:'right', color:'#fff', backgroundColor:'#2598fb'}}>Check Car History</Button>
                </section>
                <section className="section-wrapper">
                    <p> History has the largest vehicle details online database in India with information on over twenty two crore vehicles across the country. The website contains vehicle history data and other related information on its database, making it the biggest open source platform for verified vehicle owners and Vehicle Details. You can get up to 50 different history records and vehicle details within ten seconds. </p>
                    <p>Obtaining data from History is simple, user friendly and authentic. All you have to do is,</p>
                    <ol style={{paddingLeft:'30px'}}>
                        <li>Enter a vehicle's registration number</li>
                        <li>Click submit to receive the vehicle details in under 10 seconds</li>
                        <li>Download and share as per your convenience</li>
                    </ol> 
                    <p>You can easily find vehicle details in sections including the owner's name, city, registration date, engine number, model, vehicle fitness report validity, loan history, resale history to name a few. With correct vehicle details in your hand, you can ensure peace of mind and the right use of money while buying a used vehicle. History is a quick and easy to understand tool, suited for all vehicle types. The data is verified by reliable sources and is being presented in simple manner so that you get the maximum benefits. If you are planning to buy a Used Car or Bike, you can begin your research by clicking on the link- <a href="/" target="_blank">https://vehiclehistory.in/.</a></p>
                </section>
                <section className="section-wrapper">
                    <h2 className="sub-heading">Tips To Avoid Car Theft</h2>
                    <p>
                        Car theft is increasing day by day and it is our responsibility to do as much as we can to avoid this. Car thieves are evolving with new ways to commit this crime, and so, we need to keep a check on all the safety and security features of our car. Remember that nowadays thieves are also becoming quite organised and with a well-planned scene, they can take your vehicle far away in a few seconds. Here are some tips to avoid car theft.
                    </p>
                    <ul style={{paddingLeft:'30px'}}>
                        <li>First of all, do not leave the windows, the doors and the boot of your car open even if you leave your car for a couple of minutes.</li>
                        <li>Always try to park in a well-lit area where it will be visible properly. When you need to park your car at night, try to find a well-lit parking area.</li>
                        <li>Park your car at a place where its front will be facing a barrier or an obstacle.</li>
                        <li>Many cars these days come with various features to avoid theft. Use all the protection features and devices available in your car.</li>
                        <li>Always use VIN etching. This is a very important measure to prevent car theft as this chemically etches the Vehicle Identification Number or registration number on the windows of your vehicle.</li>
                        <li>If your car is parked in a garage, do not forget to close and lock the garage doors properly.</li>
                        <li>Do not leave the keys with car park employees or any other person at service outlets.</li>
                        <li>Do not keep registration papers in the car; this will prevent the thief from selling it off in the black market.</li>
                    </ul>
                </section>
                <section className="section-wrapper">
                    <h2 className="sub-heading">How To Check Any Car's History Online </h2>
                    <p>While buying a used car, there are so many things that need to be kept in mind. When you buy a used car, it is necessary to check its overall health and, also, all its body parts need to be checked properly. If you miss anything, it could cause you trouble and you may end up spending a lot of money on its repair work. So, it is better to check the history of a used vehicle before buying it. Nowadays, you can also check the history of any car online with the help of online tools like History. </p>
                    <p>By providing the registration number, you can get all the necessary information about any car. Car history check is one of the most important steps in the whole process of buying a used car as this way you get to know the hidden information about that particular car and how it has been used. Checking a car's history online is very simple and convenient. You just need to enter the registration number of the car and you will get all the information about the car's background. This way you get a quick and easy access to information about any car registered in India.</p> 
                    <p>Online car history check provides the users with an authentic data which plays an important role in your used car buying process. Moreover, it helps you make an informed buying decision. The online car history reports generally contain information like blacklist details, registration details, car ownership details, car loan details, etc. </p>
                </section>
                <section className="section-wrapper">
                    <h2 className="sub-heading">Check Any Vehicle's History Online!</h2>
                    <p>Are you worried that the used car or bike you are planning to purchase may have a doubtful past? Instead of worrying, simply dash to vehiclehistory.in and check vehicle history in 10 seconds. You can take a more informed decision with confidence when you know that there is nothing notorious in the past of a pre-owned vehicle.</p>
                    <p>
                        For the convenience of automobile buyers &amp; sellers and to build trust in the market, History is a great service, offering on demand vehicle's history report. It is India's first online used vehicle history report covering 42 history records.
                    </p>
                    <p>
                        This service will help buyers know the complete past of a second hand vehicle based on the registration number. It is easy to know about the history of the vehicle in 10 seconds, following are the steps
                    </p> 
                    <p style={{paddingLeft:'30px'}}>
                        1.	Visit vehiclehistory.in/download app for Android phone <br />
                    2.	Enter a valid registration number of a vehicle <br />
                    3.	Click "Check Now" <br />
                    4.	Download or share the vehicle history report from the link <br />
                    </p>
                    <p>
                        History Premium report is available @ ₹ 129/-. You can find out the history of the vehicle and the name of the vehicle's owner along with the details like whether it is a petrol or diesel vehicle, loan details, number of owners, accidental history, theft report and more. History offers details of over 20 crore vehicles pan India at one place.
                    </p>
                    <p>
                        What's stopping you? Make sure to check vehicle's history before you buy your next used vehicle.
                    </p>
                </section>                
            </Container>
        </div>
    )
}
export default CheckVechicleDetails;