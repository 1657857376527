import { makeStyles, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  headerWrapper: {
    textAlign: 'center',
    paddingBottom: '20px',
    '& .icon-block': {
      marginRight: '20px',
      textAlign: 'center',
      fontSize: '100px',
      '& .failure-img': {
        width: '100px'
      }
    },
    '& .message-block': {
      '& .heading': {
        margin: '0',
        fontSize: '34px',
        fontWeight: '500',
        '& span': {
          fontSize: '20px',
          display: 'block',
          fontWeight: '400',
          color: '#6f727b',
          marginTop: '20px'
        }
      }
    },
    '& .message-error': {
      '& .icon-block': {
        width: '100px',
        height: '100px',
        margin: 'auto',
        backgroundColor: 'yellow',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
        '& svg': {
          fontSize: '46px'
        }
      }
    },
    '& .download-report-block': {
      margin: '0 auto 20px',
      textAlign: 'center',
      paddingTop: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      '& .message-block': {
        textAlign: 'center',
        '& .heading': {
          fontSize: '16px',
          '& span': {
            fontSize: '14px'
          }
        }
      },
      '& .icon-block': {
        fontSize: '86px'
      }
    }
  }
}));

const NoDataFound = () => {
  const { user } = useSelector(state => state.auth);
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.headerWrapper}>
      <div className="message-success">
        <div className="icon-block">
          <img
            src="https://cdn1.acedms.com/photos/images/web/beta/images/history/failure.svg"
            className="failure-img"
            alt=""
          />
        </div>
        <div className="message-block">
          <h5 className="heading">
            Sorry {user.name} there was no data found in our record !
            <span>
              If payment was processed , then a refund will be initiated
              immediately.
            </span>
          </h5>
        </div>
      </div>
      <div className="download-report-block">
        <Button
          color="primary"
          variant="contained"
          className="download-button"
          onClick={() => history.push('/')}
        >
          Check another vehicle
        </Button>
      </div>
    </div>
  );
};

export default NoDataFound;
