import React from 'react';
import { Container, Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './static-pages.scss'
function Parameters(){
    return(
        <div id="why-history" className="static-page-wrapper">
            <Container className="container">
                <h1 className="section-heading">Parameters</h1>
                <section className="section-wrapper">
                    <figure>
                        <img data-src="https://cdn1.acedms.com/images/parameters-img.jpg" data-filter="w811" class="" style={{width: '100%'}} src="https://cdn1.acedms.com/w811/images/parameters-img.jpg.webp" />
                    </figure>
                </section>
            </Container>
        </div>
    )
}
export default Parameters;