import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { encodeData } from '../../helper/encoderDecoder';
import Cookies from 'js-cookie';
import axios from 'axios';
const initialState = {
  loading: true,
  authenticated: false,
  user: {
    id: '',
    name: '',
    email: '',
    mobile: ''
  },
  error: ''
};
export const loginUser = createAsyncThunk('login/user', body => {
  return axios
    .post('/drlogin/history-login', { user_id: body.userId, otp: body.code })
    .then(res => {
      return res.data;
    });
});

export const logoutUser = createAsyncThunk('logout/user', () => {
  return axios.get('/drapi/logout').then(res => {
    sessionStorage.clear();
    return res.data;
  });
});
export const loginSlice = createSlice({
  name: 'loginUser',
  initialState,
  reducers: {
    authDataSuccess: (state, { payload }) => {
      (state.loading = false),
        (state.authenticated = payload.code == 'success' ? true : false),
        (state.user.id = payload.data._id || ''),
        (state.user.name = payload.data.name || ''),
        (state.user.email = payload.data.email || ''),
        (state.user.mobile = payload.data.mobile || ''),
        (state.error = '');
    },
    authDataFailed: (state, { payload: error }) => {
      (state.loading = false),
        (state.authenticated = false),
        (state.error = error);
    }
  },
  extraReducers: builder => {
    //login user conditions

    builder.addCase(loginUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      (state.loading = false),
        (state.authenticated =
          action.payload.data.code == 'success' ? true : false),
        (state.user.id = action.payload.data.data._id || ''),
        (state.user.name = action.payload.data.data.name || ''),
        (state.user.email = action.payload.data.data.email || ''),
        (state.user.mobile = action.payload.data.data.mobile || ''),
        (state.error = action.payload.data.errors || '');
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      (state.loading = false),
        (state.authenticated = false),
        (state.error = action.error.message);
    });

    //logout Conditions
    builder.addCase(logoutUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      (state.loading = false),
        (state.authenticated = false),
        (state.user.id = ''),
        (state.user.name = ''),
        (state.user.email = ''),
        (state.user.mobile = ''),
        (state.error = '');
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      (state.loading = false),
        (state.authenticated = false),
        (state.error = action.error.message);
    });
  }
});
