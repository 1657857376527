import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import AddressDetails from './AddressDetails';
import Steps from '../Steps';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  stepsMainWrapper: {
    '& .content-wrapper': {
      backgroundColor: '#fff',
      padding: '20px'
    },
    '& button': {
      textTransform: 'capitalize',
      padding: '8px 30px'
    },
    '& .MuiButton-contained.Mui-disabled': {
      backgroundColor: '#a2adbd',
      color: '#fff'
    },
    [theme.breakpoints.down('sm')]: {
      '& .content-wrapper': {
        paddingLeft: '0',
        paddingRight: '0',
        backgroundColor: '#fbfbfb'
      }
    }
  }
}));

export default function UserAddressDetails(props) {
  const classes = useStyles();
  const [disableEnable, setDisableEnable] = React.useState(true);
  const [setPanel, setPanelActive] = React.useState('box-panel');
  const handleEmailUpdate = event => {
    if (event.target.value != '') {
      setDisableEnable(false);
      setPanelActive('box-panel active');
    } else {
      setDisableEnable(true);
      setPanelActive('box-panel');
    }
  };

  return (
    <div className={`${classes.root} ${classes.stepsMainWrapper}`}>
      {/* <Steps stateIndex="0" stepType="active" /> */}
      <div className="content-wrapper">
        <div className={classes.instructions}>
          <AddressDetails
            setBackground={setPanel}
            disableEnable={disableEnable}
            emailUpdate={handleEmailUpdate}
            handleModalOpen={props.handleModalOpen}
          />
        </div>
      </div>
    </div>
  );
}
