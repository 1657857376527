import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import { useSelector,useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  deliveryAddressWrapper: {
    '& .sub-heading': {
      margin: '0'
    },
    '& .detail-block': {
      '& .detail-panel': {
        paddingLeft: '10px'
      }
    }
  }
}));

export default function DeliveryAddress(props) {
  const classes = useStyles();
  const dispatch=useDispatch();
  const {authenticated,user} = useSelector(state => state.auth);
  
  return (
    <>{
      authenticated&&
      <div className={classes.deliveryAddressWrapper}>
      <div className="detail-block">
        <Radio
          color="primary"
          onChange={props.handleRadioCheck}
          value="deliveryAddress"
          name="addressCheck"
          inputProps={{ 'aria-label': 'A' }}
          checked={true}
        />
        <div className="detail-panel">
          <h5 className="sub-heading">{user.name}</h5>
          <div className="contact-mail-phone">
            <Grid container spacing={9}>
              <Grid item xs={6}>
                <a>{user.email}</a>
              </Grid>
              <Grid item xs={6}>
                {user.mobile}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  }
    </>
  );
}
