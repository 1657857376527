import React, { useEffect, useState } from 'react';
import { makeStyles, Container, Grid, Hidden } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Navigation from './Navigation';
import DownloadApp from './DownloadApp';
import { useSelector } from 'react-redux';
const useStyles = makeStyles(theme => ({
  headerContainer: {
    background: '#fbfbfb',
    position: 'fixed',
    width: '100%',
    left: '0',
    top: '0',
    zIndex: '99',
    [theme.breakpoints.up('md')]: {},
    '& .container': {
      padding: theme.spacing(2, 1.25),
      maxWidth: '1170px',
      ['@media(min-width: 1370px)']: {
        maxWidth: '1280px'
      }
    },
    '& .logo': {
      maxWidth: '180px',
      margin: '0',
      '& img': {
        maxWidth: '100%'
      }
    },
    '& .navigation-section': {
      textAlign: 'right',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right',
      fontSize: '16px'
    },
    '& .back-drop': {
      position: 'fixed',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: '2',
      '& .navigation': {
        background: '#fff',
        height: '100%',
        width: '50%',
        position: 'absolute',
        top: '0',
        left: '0',
        padding: '10px',
        '& li, & a, & .loginBtn span': {
          display: 'block',
          textAlign: 'left',
          padding: '5px 10px'
        }
      }
    },
    '& .logo-wrapper': {
      display: 'flex',
      alignItems: 'center',
      '& .menu-icon': {
        marginRight: '10px',
        fontSize: '30px'
      }
    }
  }
}));

export default function Header() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const prevOpen = React.useRef(open);
  const { value } = useSelector(state => state.panel);
  useEffect(()=>{
    if(value) handleLoginPopup();
  },[value])
  const handleLoginPopup = () => {
    setOpen(!open);
  }
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.headerContainer}>
      <Container maxWidth="lg" className="container">
        <Grid container>
          <Grid item sm={3} xs={8} className="logo-wrapper">
            <Hidden mdUp>
              <MenuIcon onClick={handleToggle} className="menu-icon" />
            </Hidden>
            <a href="/">
              <figure className="logo">
                <img
                  src={`${process.env.IMG_URL}/photos/images/web/beta/images/history/history-logo.png`}
                  alt="History | Used Vehicle History Report"
                />
              </figure>
            </a>
          </Grid>
          <Grid item sm={9} xs={4} className="navigation-section">
            <Hidden smDown>
              <Navigation hidemenu={handleClose} handleLoginPopup={handleLoginPopup}/>
              <DownloadApp />
            </Hidden>
            <Hidden mdUp>
              {open ? (
                <div className="back-drop" onClick={handleClose}>
                  <div
                    className="navigationContent"
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    <Navigation hidemenu={handleClose} handleLoginPopup={handleLoginPopup} />
                  </div>
                </div>
              ) : (
                ''
              )}
              <DownloadApp />
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
