import { makeStyles, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  headerWrapper: {
    textAlign: 'center',
    paddingBottom: '20px',
    '& .icon-block': {
      marginRight: '20px',
      textAlign: 'center',
      fontSize: '100px'
    },
    '& .message-block': {
      '& .heading': {
        margin: '0',
        fontSize: '34px',
        fontWeight: '500',
        paddingTop: '20px',
        '& span': {
          fontSize: '20px',
          display: 'block',
          fontWeight: '400',
          color: '#6f727b',
          marginTop: '20px'
        }
      }
    },
    '& .message-error': {
      '& .icon-block': {
        width: '100px',
        height: '100px',
        margin: 'auto',
        backgroundColor: 'yellow',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
        '& svg': {
          fontSize: '46px'
        }
      }
    },
    '& .download-report-block': {
      margin: '0 auto 20px',
      textAlign: 'center',
      paddingTop: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      '& .message-block': {
        textAlign: 'center',
        '& .heading': {
          fontSize: '16px',
          paddingTop: '20px',
          '& span': {
            fontSize: '14px'
          }
        }
      },
      '& .icon-block': {
        fontSize: '86px'
      }
    }
  }
}));

const PaymentFailure = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div className={classes.headerWrapper}>
      <div className="message-success">
        <div className="message-block">
          <h5 className="heading">
            Payment failure
            <span>Oops! the payment failed.</span>
          </h5>
        </div>
      </div>
      <div className="download-report-block">
        <Button
          color="primary"
          variant="contained"
          className="download-button"
          onClick={() => history.push('/checkout')}
        >
          Back to Cart
        </Button>
      </div>
    </div>
  );
};

export default PaymentFailure;
