import React from "react";
import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import '../static-pages.scss'

const VaharInformationOnline = ()=>{
    return(
        <div id="history-blogs" className="static-page-wrapper">
            <Container className="container">
                <section className="section-wrapper">
                    <h1 className="section-heading">How to get Vahan Information Online ?</h1>
                    <p>Gone are the days when we had to visit regional transport offices (RTOs) and district transport offices (DTOs) to get vehicle owner details or vehicle registration details. Unfortunately, hit and run cases are more than usual. Even if you manage to capture the vehicle’s registration number, you might not be able to find its details easily.</p>
                    <p>Well, not anymore!</p>
                    <p>
                        Vehicle history is India’s largest national website that offers history reports for more than two hundred million registered vehicles in India.
                    </p>
                    <p>
                        History’s database holds the history reports of nearly 200 million vehicles of the total 220 million registered vehicles. The history report provides comprehensive information of the background of any vehicle that covers up to 50 different history records.
                    </p>
                    <p>
                        History is known to be India’s most comprehensive vehicle history check built using Droom’s proprietary technology. Be it the vehicle’s authenticity or its loan history, you can get every detail on <a href="/" target="_blank">vehicle history.in.</a>
                    </p>
                </section>
                <section className="section-wrapper">
                    <h2 className="sub-heading">How to get vehicle information on History?</h2>
                    <p>The process at History is a two-step process if you want to find the <a href="/" target="_blank">vahan information online.</a></p>
                    <ul style={{paddingLeft:'30px'}}>
                        <li>At the History homepage, you will find a dialogue box that says “ENTER VALID REGISTRATION NUMBER.” Here you must insert the vehicle registration number.</li>
                        <li>Click on the “SEARCH HISTORY” button.</li>
                        <li>Afterward, you will get the history report of the required vehicle. Then, you can download and share it further.</li>
                    </ul>
                    <p>
                        The vehicle history report gives information available about all kinds of private and commercial vehicles. All the information is stored in Droom’s database.
                    </p>
                    <p>
                        The two-step process will be done in under 10 seconds, which will provide you the following details about the vehicle:
                    </p>
                    <p>
                        The authenticity of the vehicle information provided by the seller
                    </p>
                    <ul style={{paddingLeft:'30px'}}>
                        <li>If the vehicle is blocklisted</li>
                        <li>Loan history of the vehicle</li>
                        <li>Vehicle fitness report validity</li>
                        <li>The age of a vehicle</li>
                        <li>The number of times it has been resold.</li>
                    </ul>
                </section> 
                <section className="section-wrapper">
                    <h2 className="sub-heading">What are the benefits of a history report?</h2>
                    <p>
                        During traffic police checkups, vehicle RC, driving license, and other vehicle details and its owner are demanded. Failing to do so costs the vehicle owner a huge fine. History comes to rescue all those who do not carry their documents with them. All you have to do is visit History’s website, enter your registration number, and within 10 seconds, the History of your vehicle will be downloaded on your phone.
                    </p>
                    <p>
                        The era of digitization has made it possible to carry everything we desire on our mobile phones. Using History will solve your problem of not carrying the documents and save you from emergency fines. You can alternatively save the downloaded report on the digilocker application.
                    </p>
                </section> 
                <section className="section-wrapper">
                    <h2 className="sub-heading">Why History?</h2>
                    <p>
                        The database available on the history website is useful for buyers and sellers as it provides authentic information about a vehicle’s safety and trust value. It is most useful while buying second-hand vehicles as it will inform the buyer about its original purchase date and the number of times the vehicle has been resold. This gives the buyer a clear picture of whether or not to buy it.
                    </p>
                    <p>
                        There are more than a thousand Regional Transport Offices across India. History holds the data for more than 19 crore vehicles registered in these RTOs. This data is further put together and verified from multiple authoritative sources to update the data of more than 60 lakh vehicles in real-time.
                    </p>
                    <p>
                        History Premium report is available @ ₹ 129/-
                        History Premium report, provides information including flood history damage, blocklist module, registration module, advanced registration module, address module, vehicle module, insurance module, and hypothecation module. At the same time, both the reports will provide basic 6 points about the vehicle.
                    </p>
                </section>   
                <section className="section-wrapper">
                    <h2 className="sub-heading">FAQs</h2>
                    <h3 className="sub-heading">Is the history report authentic?</h3>
                    <p>The history report is unbiased and provides authentic information of the vehicle registered under an RTO.</p>
                    <h3 className="sub-heading">Is the history report useful?</h3>
                    <p>Yes, it is useful as it can be accessed anywhere and saves you from carrying vehicle documents everywhere.</p>
                    <h3 className="sub-heading">Is the history report free of cost?</h3>
                    <p>History Premium report is available @ ₹ 129/-</p>
                    <h3 className="sub-heading">How can I receive my history report?</h3>
                    <p>On the history website, enter the valid registration number of your vehicle, and you can download it using the link provided. This report is also sent via email.</p>
                </section>          
            </Container>
        </div>
    )
}
export default VaharInformationOnline;